import React from 'react';
import PropTypes from 'prop-types';
// @mui
import { Dialog, DialogTitle, DialogActions, IconButton, Slide, useTheme, alpha } from '@mui/material';
import Iconify from './Iconify';
import { DialogAnimate, varFade } from './animate';
// utils
import cssStyles from '../utils/cssStyles';

// ----------------------------------------------------------------------

CustomModal.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  open: PropTypes.bool,
  actions: PropTypes.node,
  onClose: PropTypes.func,
  hasClose: PropTypes.bool,
  children: PropTypes.node,
  isBackdropClosable: PropTypes.bool,
  hasTransition: PropTypes.bool,
};

const Transition = React.forwardRef((props, ref) => <Slide direction="up" ref={ref} {...props} />);

export default function CustomModal({
  children,
  title = '',
  actions,
  open,
  onClose,
  hasClose = true,
  isBackdropClosable = true,
  // hasTransition = true,
  ...other
}) {
  const theme = useTheme();

  const handleClose = (e, r) => {
    if (!isBackdropClosable && r === 'backdropClick') {
      return false;
    }

    if (!isBackdropClosable && r === 'escapeKeyDown') {
      return false;
    }

    onClose(e, r);
    return true;
  };

  return (
    <DialogAnimate
      fullWidth
      open={open}
      onClose={handleClose}
      scroll={'body'}
      // {...(hasTransition ? { TransitionComponent: Transition } : null)}
      {...other}
      // transitionDuration={{ enter: 100, exit: 0 }}
    >
      {hasClose && (
        <IconButton
          aria-label="close"
          onClick={() => onClose()}
          color="primary"
          size="small"
          sx={{
            position: 'absolute',
            right: 12,
            top: 12,
            zIndex: 1,
            color: '#fff',
            background: 'linear-gradient(0deg, #1CBC40 0%, #21592E 100%)',
            '&:hover': {
              backgroundColor: (theme) => alpha('#dd0000', 0.8),
            },
          }}
        >
          <Iconify icon={'ep:close'} />
        </IconButton>
      )}
      {title && <DialogTitle>{title}</DialogTitle>}
      {children}
      {actions && <DialogActions>{actions}</DialogActions>}
    </DialogAnimate>
  );
}
