import { useMemo } from 'react';
import PropTypes from 'prop-types';
import { m } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import { Backdrop, Box } from '@mui/material';
//
import ProgressBar from './ProgressBar';
import { useSelector } from '../redux/store';
import Logo from './Logo';

// ----------------------------------------------------------------------

const RootStyle = styled(Backdrop)(({ theme }) => ({
  right: 0,
  bottom: 0,
  zIndex: 99999,
  width: '100%',
  height: '100%',
  position: 'fixed',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const boxShadow = {
  min: '0px 0px 25px 5px rgba(0,255,0,0.75)',
  max: '0px 0px 25px 10px rgba(0,255,0,0.75)',
};

// ----------------------------------------------------------------------

LoadingScreen.propTypes = {
  loadable: PropTypes.bool,
};

export default function LoadingScreen({ loadable, ...other }) {
  const { openPageLoadingCount } = useSelector((x) => x.settings);

  const isDisplay = useMemo(() => loadable || openPageLoadingCount > 0, [openPageLoadingCount, loadable]);

  if (!isDisplay) return <></>;

  return (
    <>
      <ProgressBar />

      <RootStyle open={true} {...other}>
        <Box
          sx={{
            boxShadow: boxShadow.max,
            border: '2px solid #fff',
            backgroundColor: 'rgba(0,0,0,0.2)',
            width: '200px',
            height: '200px',
            borderRadius: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
          component={m.div}
          animate={{
            scale: [1, 0.98, 1],
            boxShadow: [boxShadow.max, boxShadow.min, boxShadow.max],
          }}
          transition={{
            duration: 1.5,
            ease: 'easeInOut',
            repeatDelay: 0,
            repeat: Infinity,
          }}
        >
          <Logo disabledLink sx={{ width: '80%', height: 'auto' }} />
        </Box>
      </RootStyle>
    </>
  );
}
