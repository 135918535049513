// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const vn = {
  game: 'Trò chơi',
  games: 'Trò chơi',
  sport: 'Thể thao',
  center: 'Trung tâm',
  mines: 'MỎ',
  crash: 'SẬP',
  lucky_colour: 'MÀU SẮC MAY MẮN',
  dice: 'XÚC XẮC',
  limbo: 'LIMBO',
  lucky_gift: 'QUÀ TẶNG MAY MẮN',
  all_games: 'Tất cả trò chơi',
  game_history: 'Lịch sử trò chơi',
  coupon_code: 'Mã khuyến mãi',
  telegram: 'Telegram',
  customer_service: 'Dịch vụ khách hàng',
  player: 'Người chơi',
  winner: 'Người chiến thắng',
  all_bets: 'Tất cả cược',
  my_bets: 'Cược của tôi',
  name: 'Tên',
  time: 'Thời gian',
  bet_amount: 'Số tiền cược',
  prediction: 'Dự đoán',
  payout: 'Trả thưởng',
  login: 'ĐĂNG NHẬP',
  register: 'ĐĂNG KÝ',
  username_placeholder: 'Tên người dùng',
  please_input_your_x: 'Vui lòng nhập {{x}} của bạn',
  captcha: 'CAPTCHA',
  forgot_password: 'Quên mật khẩu',
  minor_note: 'Để truy cập trang web này, hãy đảm bảo bạn trên 18 tuổi và đồng ý',
  terms_of_service: 'Điều khoản dịch vụ',
  by_signing_in: 'Bằng cách đăng nhập hoặc đăng ký, bạn đồng ý với',
  dont_have_account: 'Chưa có tài khoản?',
  sign_up: 'Đăng ký',
  username: 'Tên người dùng',
  email: 'Email',
  full_name: 'Họ và tên',
  confirm_18: 'Tôi xác nhận rằng tôi trên 18 tuổi và tôi đã đọc',
  already_have_account: 'Đã có tài khoản?',
  share: 'Chia sẻ',
  deposit: 'Nạp tiền',
  bonus: 'Tiền thưởng',
  app: 'Ứng dụng',
  load_more: 'Tải thêm',
  details: 'Chi tiết',
  helps: 'GIÚP ĐỠ',
  kyc_policy: 'Chính sách KYC',
  privacy_policy: 'Chính sách bảo mật',
  responsible_gaming: 'Chơi game có trách nhiệm',
  terms_and_condition: 'Điều khoản và điều kiện',
  faq: 'Câu hỏi thường gặp',
  about_us: 'VỀ CHÚNG TÔI',
  amount: 'Số tiền',
  extra: 'Thêm',
  deposit_bonus: 'Tiền thưởng nạp tiền',
  do_not_participate: 'Không tham gia các chương trình khuyến mãi',
  payment_problem: 'Vấn đề thanh toán',
  click_for_help: 'Nhấp để được trợ giúp',
  deposit_notes: 'Ghi chú nạp tiền',
  available_balance: 'Số dư khả dụng',
  withdraw: 'Rút tiền',
  withdrawal_notes: 'Ghi chú rút tiền',
  withdrawal_note1: 'Số lần rút tiền trong hiện tại',
  withdrawal_note1_1: 'vip',
  withdrawal_note2: 'Phí rút tiền:',
  min: 'Tối thiểu:',
  max: 'Tối đa:',
  opening_times: 'Giờ làm việc:',
  withdrawal_note3: '1. Thời gian rút tiền nhanh nhất trong vòng 10 phút',
  withdrawal_note4:
    '2. Đảm bảo thông tin rút tiền của bạn chính xác, nếu thông tin rút tiền sai, có thể gây mất tiền của bạn.',
  withdrawal_note5: '3. Xin vui lòng liên hệ với chúng tôi nếu bạn có bất kỳ câu hỏi nào.',
  notifications: 'Thông báo',
  invalid_x: '{{x}} không hợp lệ',
  captcha_not_match: 'Captcha không khớp',
  invalid_x_must_be_atleast_y_characters: '{{x}} không hợp lệ, phải có ít nhất {{y}} ký tự',
  x_is_required: '{{x}} là bắt buộc',
  phone_number: 'Số điện thoại',
  phone_num_x_max: 'Số điện thoại tối đa {{x}} chữ số',
  email_sent: 'Email đã được gửi, vui lòng kiểm tra email của bạn.',
  enter_valid_email: 'Vui lòng nhập email hợp lệ.',
  confirm: 'Xác nhận',
  password_atleast_6_characters: 'Mật khẩu phải có ít nhất 6 ký tự.',
  password_reset_success: 'Đặt lại mật khẩu thành công!',
  new_password: 'Mật khẩu mới',
  bank: 'Ngân hàng',
  bank_transfer: 'Chuyển khoản ngân hàng',
  bank_name: 'Tên ngân hàng',
  account_name: 'Tên tài khoản',
  account_number: 'Số tài khoản',
  pix_number: 'Số PIX',
  drop_or_select: 'Kéo hoặc chọn tệp',
  drop_files_here: 'Kéo tệp vào đây',
  transfer_to: 'Chuyển khoản đến',
  upload_receipt: 'Tải hóa đơn lên',
  select_bonus: 'Chọn tiền thưởng',
  no_promotion: 'Không có khuyến mãi',
  please_upload_receipt: 'Vui lòng tải hóa đơn lên',
  bank_account: 'Tài khoản ngân hàng',
  bank_branch: 'Chi nhánh ngân hàng',
  pay_id: 'Mã thanh toán',
  add_account: 'Thêm tài khoản',
  account_holder_name: 'Tên chủ tài khoản',
  name_of_the_account_holder: 'Tên của chủ tài khoản',
  fill_in_bank_account: 'Điền thông tin tài khoản ngân hàng',
  fill_in_pay_id: 'Điền mã thanh toán',
  fill_in_bank_branch: 'Điền thông tin chi nhánh ngân hàng',
  withdraw_information: 'Thông tin rút tiền',
  note: 'Ghi chú',
  amount_withdrawn: 'Số tiền đã rút',
  amount_no_exceed_balance: 'Số tiền rút không được vượt quá số dư hiện có',
  minimum_withdraw_amount_is_x: 'Số tiền rút tối thiểu là {{x}}',
  transaction_history: 'Lịch sử giao dịch',
  date_from: 'Ngày từ',
  date_to: 'Ngày đến',
  account_name_must_be_4: 'Tên tài khoản phải dài hơn 4 ký tự',
  account_number_must_be_x: 'Số tài khoản phải dài từ 10-14 ký tự',
  minimum_length_is_x: 'Độ dài tối thiểu là {{x}}',
  maximum_length_is_x: 'Độ dài tối đa là {{x}}',
  payment_method: 'Phương thức thanh toán',
  mode: 'Chế độ',
  no_bank_account: 'Bạn chưa có tài khoản nào để rút tiền, Thêm ngay',
  please_select_bank: 'Vui lòng chọn tài khoản ngân hàng',

  bet_history: 'Lịch sử cược',
  game_provider: 'Nhà cung cấp trò chơi',
  bet_count: 'Số lượng cược',
  win_loss: 'Thắng/thua',
  rebate: 'Hoàn tiền',
  turnover: 'Doanh thu',
  game_category: 'Danh mục trò chơi',

  you_have_x_unread_messages: 'Bạn có {{x}} tin nhắn chưa đọc',
  deposit_amount_between_x_and_y: 'Số tiền nạp phải nằm giữa {{x}} và {{y}}',
  minimum_amount_is_x_y: 'Số tiền tối thiểu là {{x}}{{y}}',

  // all
  all: 'Tất cả',
  title: 'Tiêu đề',
  date: 'Ngày',
  type: 'Loại',
  status: 'Trạng thái',
  remark: 'Ghi chú',
  category: 'Danh mục',
  please_select_x: 'Vui lòng chọn {{x}}',
  save: 'Lưu',

  change_password: 'Đổi mật khẩu',
  current_password: 'Mật khẩu hiện tại',
  confirm_password: 'Xác nhận mật khẩu mới',
  submit: 'Gửi',
  password_changed: 'Mật khẩu đã được đổi',
  x_must_be_y_characters: '{{x}} phải ít nhất {{y}} ký tự',
  reset_password: 'Đặt lại mật khẩu',
  casino: 'Sòng bạc',
  slots: 'Máy slot',
  live_casino: 'Sòng bạc trực tuyến',
  fishing: 'Câu cá',
  sports: 'Thể thao',
  cards: 'Thẻ bài',
  clear: 'Xóa',
  search: 'Tìm kiếm',
  new: 'Mới',
  logout: 'Đăng xuất',
  choose_date: 'Chọn ngày',
  select_both_date: 'Chọn cả ngày từ và đến',
  from: 'Từ',
  to: 'Đến',
  apply: 'Áp dụng',
  promotions: 'Khuyến mãi',
  learn_more: 'TÌM HIỂU THÊM',
  password_doesnt_match: 'Mật khẩu không khớp',
  cancel: 'Hủy',
  confirm_to_pay: 'Xác nhận thanh toán',
  deposit_amount: 'Số tiền nạp',
  loading_please_wait: 'Đang tải, vui lòng đợi',
  account: 'Tài khoản',
  profile: 'Hồ sơ',
  bank_list: 'Danh sách ngân hàng',
  in_maintenance: 'Đang bảo trì',
  birthday: 'Ngày sinh',
  birthday_bonus: 'Nhập ngày sinh của bạn để nhận thưởng! 🎉🎁',
  referral_code: 'Mã giới thiệu',
  friend_referral_code: 'Mã giới thiệu bạn bè',
  no_friend: '( để trống nếu không có bạn )',
  referral: 'Giới thiệu',
  my_referral: 'Giới thiệu của tôi',
  my_claim: 'Yêu cầu của tôi',
  claim_profit: 'YÊU CẦU LỢI NHUẬN',
  available_profit: 'Lợi nhuận khả dụng',
  claimed_profit: 'Lợi nhuận đã yêu cầu',
  all_time_profit: 'Lợi nhuận toàn thời gian',
  referral_note_on_x: 'Kiếm lợi nhuận mỗi khi bạn bè của bạn chơi trên {{x}}',
  tier: 'Cấp độ',
  commission: 'Hoa hồng',
  referred_member: 'Thành viên được giới thiệu',
  profit_earned: 'Lợi nhuận kiếm được',
  account_information: 'Thông tin tài khoản',
  invite_friends: 'Mời bạn bè',
  how_it_work: 'Cách hoạt động?',
  invite_note: 'Nhận tiền thưởng giới thiệu độc quyền khi bạn của bạn đăng ký bằng mã giới thiệu của bạn!',
  share_via: 'Chia sẻ qua',
  referral_link: 'Liên kết giới thiệu',
  total_profit: 'Tổng lợi nhuận',
  copied: 'Đã sao chép',
  nothing_to_claim: 'Không có gì để yêu cầu',
  claim_history: 'Lịch sử yêu cầu',
  downline: 'Hệ thống cấp dưới của tôi',
  last_x_digit_bank_ref_number: 'X số cuối của số tham chiếu ngân hàng',
  bank_ref_number_validation_x: 'Số tham chiếu ngân hàng là bắt buộc và phải có độ dài {{x}} ký tự',
  sort: 'Sắp xếp',
  login_directly: 'Đăng nhập trực tiếp với',
  our_game_provides: 'CÁC NHÀ CUNG CẤP TRÒ CHƠI CỦA CHÚNG TÔI',
  refer_and_reward: 'Giới thiệu & Thưởng',
  linked_successfully_x: 'Kết nối thành công với {{x}}! Vui lòng đăng ký tài khoản của bạn lần đầu tiên',
  more_x_of_y: 'Thêm {{x}}/{{y}}',
  default: 'Mặc định',
  promotion: 'Khuyến mãi',
  daily_mission: 'Nhiệm vụ hàng ngày',
  agent: 'Đại lý',
  contact_us: 'Liên hệ chúng tôi',
  download: 'Tải xuống',
  cash_claim: 'Yêu cầu tiền mặt',
  demo: 'Dùng thử',
  favorites: 'Yêu thích',
  popular: 'Phổ biến',
  claim_bonus: 'Yêu cầu thưởng',
  insufficient_balance: 'Số dư không đủ',
  currently_displaying: 'Hiện đang hiển thị {{x}} {{y}} trò chơi trong tổng số {{z}}',
  all_x: 'Tất cả {{x}}',
  no_results: 'Không tìm thấy kết quả',
  search_games: 'Tìm kiếm trò chơi',
  please_scan_using_your: 'Vui lòng quét bằng',
  vip_level: 'Cấp độ VIP',
  bonus_direct: 'TIỀN THƯỞNG TRỰC TIẾP',
  rebate_level: 'Cấp độ hoàn tiền',
  vip_header:
    'Tất cả các cược đặt trên máy slot, trò chơi trên bảng và thẻ, câu cá, video, thể thao và xổ số tại Dewakaya77 sẽ được tích lũy vĩnh viễn. Cược hợp lệ = Thu nhập vĩnh viễn và Tài sản tiền bạc!',
  vip_example:
    'Ví dụ 1: Các thành viên đạt 1 triệu trong số tiền gửi tích lũy, đạt cấp VIP 2, nhận thưởng R$210 và hoàn tiền 3,50%. Khi đạt 6 triệu trong số tiền gửi tích lũy, họ đạt cấp VIP 3 và nhận thưởng R$800, với hoàn tiền 4%',
  activity_rules: 'Quy tắc hoạt động:',
  activity_rules_1: '1. Cần phải có x3 vòng quay để yêu cầu rút tiền tất cả các khoản thưởng',
  activity_rules_2: '2. Vui lòng yêu cầu thưởng hoàn tiền trong thời hạn, nếu bạn trễ, sẽ được coi là bỏ cuộc;',
  activity_rules_3:
    '3. Việc tham gia của các thành viên vào hoạt động sẽ được hệ thống tự động tính, nếu có bất kỳ khiếu nại nào, kết quả điều tra của Dewakaya77 sẽ được ưu tiên;',
  activity_rules_4:
    "4. Nếu bạn quên tài khoản/mật khẩu thành viên của mình, vui lòng liên hệ với 'Dịch vụ khách hàng trực tuyến 24 giờ' để giúp bạn khôi phục thông tin tài khoản;",
  activity_rules_5: '5. Dewakaya77 bảo lưu quyền thay đổi, dừng hoặc hủy bỏ khuyến mãi này bất kỳ lúc nào.',
  activity_rules_6: '6. Không lưu trò chơi miễn phí, mua trò chơi miễn phí',
  activity_rules_7: '7. Không thể kết hợp với bất kỳ khuyến mãi nào',
  activity_rules_8: '8. Số tiền rút tối đa mỗi lần gửi là R$100.000,00',
  event_instructions: 'Hướng dẫn sự kiện',
  agent_p1: 'Chia sẻ liên kết độc quyền của bạn trên các ứng dụng và mạng xã hội',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube, và các nền tảng khác, nhanh chóng mời mọi người, các thành viên đăng ký qua liên kết độc quyền của bạn sẽ trở thành đại lý trực tiếp của bạn. Nhưng hãy chú ý vì các thành viên mới bạn mời phải là những người thật và hợp lệ.',
  agent_step1:
    'Nhận bạn mời, lần gửi đầu tiên thành công, bạn có thể nhận thưởng R$5. Ví dụ, nếu bạn mời 10 người gửi lần đầu, bạn có thể nhận R$50. Càng nhiều người bạn mời, bạn càng nhận được nhiều thưởng. Bạn có thể xem lịch sử và đăng ký trên trang khuyến mãi để nhận thưởng và hoa hồng cao.',
  agent_step2:
    'Nếu khách của bạn cược R$1 hoặc hơn, bạn có thể thưởng hoa hồng cao! Bạn có thể nhận lên đến 5% hoa hồng trọn đời và mời trọn đời! Hệ thống sẽ thanh toán hoa hồng của ngày hôm trước vào lúc 7 giờ sáng ngày hôm sau.',
  agent_step2_1: 'Cách nhận: Đăng nhập - Khuyến mãi mời - Thu thập hoa hồng',
  agent_table1: 'Tỷ lệ hoa hồng cược cho slot, câu cá và blockchain như sau:',
  agent_table1_1: 'Khách hàng nạp 20 cược 200',
  person: 'người',
  agent_table2_title1: 'Cược của thành viên',
  agent_table2_title2: 'Chỉ trò chơi slot',
  agent_table2_example:
    'Ví dụ: Bạn có 100 thành viên, và tổng giá trị thực của các cược ước tính là 1 triệu reais mỗi ngày. Hoa hồng bạn nhận được trong ngày hôm đó là: 1.000.000 x 1,20% = 12.000 reais. Thu nhập hàng tháng 100.000 rất dễ đạt được.',
  reminder: 'Nhắc nhở',
  agent_reminder:
    'Một tài khoản thành viên với cùng IP, cùng thiết bị, tên, thẻ ngân hàng và thông tin khác chỉ có thể tham gia một lần, và bất kỳ ai vi phạm quy tắc sẽ không nhận được lợi ích từ khuyến mãi này. Hoặc nhóm sẽ bị tịch thu và tài khoản sẽ bị đóng băng vĩnh viễn.',
  agent_step3_1:
    'Sự tham gia của các thành viên vào hoạt động sẽ được hệ thống tự động tính, nếu có bất kỳ khiếu nại nào, kết quả phân tích của Dewakaya77 sẽ được ưu tiên.',
  agent_step3_2:
    'Nếu bạn quên tài khoản/mật khẩu thành viên của mình, vui lòng liên hệ với đội ngũ dịch vụ khách hàng 24 giờ của chúng tôi để giúp bạn khôi phục thông tin tài khoản.',
  agent_step3_3: 'Dewakaya77 bảo lưu quyền thay đổi, ngắt quãng hoặc hủy bỏ khuyến mãi bất kỳ lúc nào.',
  agent_step3_4: 'Đại lý có thể nhận thưởng hoa hồng dưới dạng tiền mặt hoặc tín dụng trong ví.',
  agent_step3_5: 'Không lưu trò chơi miễn phí, mua trò chơi miễn phí.',
  agent_step3_6: 'Không thể kết hợp với bất kỳ khuyến mãi và nạp tiền nào.',
  agent_step3_7: 'Chỉ có sẵn cho trò chơi slot.',
  promo_concluded: 'Khuyến mãi đã kết thúc! Hãy kiểm tra lại vào ngày mai!',
  can_only_claim: 'Chỉ có thể yêu cầu thưởng giữa {{x}} đến {{y}} giờ theo giờ Brazil.',
  please_login: 'Vui lòng đăng nhập trước để yêu cầu thưởng!',
  back_to_home: 'Quay lại Trang chủ',
  bonus_claimed: 'Thưởng đã được yêu cầu!',

  network_error: 'Lỗi mạng',
  we_have_sent_otp: 'Chúng tôi đã gửi OTP đến số điện thoại của bạn',
  your_otp_expire: 'OTP của bạn sẽ hết hạn trong',
  didnt_receive: 'Không nhận được tin nhắn?',
  send_again: 'Gửi lại',
  resend_again: 'Gửi lại trong',
  invalid_verification: 'Mã xác minh không hợp lệ!',
  otp_expired: 'OTP đã hết hạn, vui lòng nhập lại số điện thoại và thử lại!',
  otp_expired_redirect: 'OTP đã hết hạn! Đang chuyển hướng đến trang đăng nhập',
  captcha_check_failed: 'Kiểm tra captcha thất bại!',
  verify_phonenumber: 'Xác minh số điện thoại',
  send_otp: 'Gửi OTP',
  success_verification: 'Số điện thoại của bạn đã được xác minh!',
  press_get_code: 'Nhấn nút NHẬN MÃ ĐỂ NHẬN MÃ XÁC MINH QUA SMS',
  otp_sent: 'Mã xác minh đã được gửi! Nhập mã xác minh bên dưới để tiếp tục',
  get_code: 'Nhận mã',
  verify: 'Xác minh',
  verification_code: 'Mã xác minh',
  otp_not_found: 'Chức năng OTP không tìm thấy!',
  yes: 'Có',
  no: 'Không',
  please_register_to_claim_bonus: 'Vui lòng đăng ký để yêu cầu thưởng!',
  home: 'Trang chủ',
  history: 'Lịch sử',
  live_transactions: 'Giao dịch trực tiếp',
  live: 'Trực tiếp',
  x_categories: '{{x}} Danh mục',
  x_category: '{{x}} Danh mục',
  live_chat: 'Trò chuyện trực tiếp',
  invite_friend: 'Mời bạn bè',
  copy_referral_link: 'Sao chép liên kết giới thiệu',
  balance: 'Số dư',
  level: 'Cấp độ',
  refresh: 'Làm mới',
  password: 'Mật khẩu',
  password_placeholder: '6 - 20 ký tự',
  optional: '(tùy chọn)',
  login_here: 'Đăng nhập tại đây',
  enter_text_on_the_left: 'Nhập văn bản bên trái',
  more_info: 'Thông tin thêm',
  back: 'Quay lại',
  number_exists: 'Số điện thoại này đã được đăng ký!',
  number_not_exists: 'Số điện thoại này chưa được đăng ký!',
  please_download_x_to_play: 'Vui lòng tải {{x}} để chơi',
  play_now: 'Chơi ngay',
};

export default vn;
