// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const ms = {
  game: 'Permainan',
  games: 'Permainan',
  sport: 'Sukan',
  center: 'Pusat',
  mines: 'MINES',
  crash: 'CRASH',
  lucky_colour: 'WARNA BERTUAH',
  dice: 'DADU',
  limbo: 'LIMBO',
  lucky_gift: 'HADIAH BERTUAH',
  all_games: 'Semua Permainan',
  game_history: 'Sejarah Permainan',
  coupon_code: 'Kod Kupon',
  telegram: 'Telegram',
  customer_service: 'Perkhidmatan Pelanggan',
  player: 'Pemain',
  winner: 'Pemenang',
  all_bets: 'Semua Pertaruhan',
  my_bets: 'Pertaruhan Saya',
  name: 'Nama',
  time: 'Masa',
  bet_amount: 'Jumlah Pertaruhan',
  prediction: 'Ramalan',
  payout: 'Pembayaran',
  login: 'LOG MASUK',
  register: 'DAFTAR',
  username_placeholder: 'Nama Pengguna',
  please_input_your_x: 'Sila Masukkan {{x}} Anda',
  captcha: 'CAPTCHA',
  forgot_password: 'Lupa Kata Laluan',
  minor_note: 'Untuk melawat laman web ini, pastikan anda berusia lebih 18 tahun dan bersetuju',
  terms_of_service: 'Terma Perkhidmatan',
  by_signing_in: 'Dengan Mendaftar Masuk atau Mendaftar, anda bersetuju dengan',
  dont_have_account: 'Belum Mempunyai Akaun?',
  sign_up: 'Daftar',
  username: 'Nama Pengguna',
  email: 'E-mel',
  full_name: 'Nama Penuh',
  confirm_18: 'Saya mengesahkan bahawa saya berusia 18 tahun dan saya telah membaca',
  already_have_account: 'Sudah mempunyai akaun?',
  share: 'Kongsi',
  deposit: 'Deposit',
  bonus: 'Bonus',
  app: 'Aplikasi',
  load_more: 'Muat Lebih Banyak',
  details: 'Butiran',
  helps: 'BANTUAN',
  kyc_policy: 'Polisi KYC',
  privacy_policy: 'Polisi Privasi',
  responsible_gaming: 'Permainan Bertanggungjawab',
  terms_and_condition: 'Terma dan Syarat',
  faq: 'Soalan Lazim',
  about_us: 'TENTANG KAMI',
  amount: 'Jumlah',
  extra: 'Tambahan',
  deposit_bonus: 'Bonus Deposit',
  do_not_participate: 'Tidak menyertai promosi',
  payment_problem: 'Masalah Pembayaran',
  click_for_help: 'Klik untuk bantuan',
  deposit_notes: 'Nota Deposit',
  available_balance: 'Baki Tersedia',
  withdraw: 'Pengeluaran',
  withdrawal_notes: 'Nota Pengeluaran',
  withdrawal_note1: 'Bilangan pengeluaran dalam semasa',
  withdrawal_note1_1: 'vip',
  withdrawal_note2: 'Yuran Pengeluaran:',
  min: 'Min:',
  max: 'Maks:',
  opening_times: 'Waktu buka:',
  withdrawal_note3: '1. Masa pengeluaran terpantas dalam masa 10 minit',
  withdrawal_note4:
    '2. Pastikan maklumat pengeluaran anda tepat, jika maklumat pengeluaran salah, ia boleh menyebabkan kehilangan dana anda.',
  withdrawal_note5: '3. Sila hubungi kami jika anda mempunyai sebarang pertanyaan.',
  notifications: 'Pemberitahuan',
  invalid_x: '{{x}} tidak sah',
  captcha_not_match: 'Captcha Tidak Sesuai',
  invalid_x_must_be_atleast_y_characters: '{{x}} tidak sah, mesti sekurang-kurangnya {{y}} aksara',
  x_is_required: '{{x}} diperlukan',
  phone_number: 'Nombor Telefon',
  phone_num_x_max: 'Nombor Telefon maksimum {{x}} digit',
  email_sent: 'E-mel dihantar, sila semak e-mel anda.',
  enter_valid_email: 'Sila masukkan e-mel yang sah.',
  confirm: 'Sahkan',
  password_atleast_6_characters: 'Kata laluan mesti sekurang-kurangnya 6 aksara.',
  password_reset_success: 'Kejayaan menetapkan semula kata laluan!',
  new_password: 'Kata Laluan Baru',
  bank: 'Bank',
  bank_transfer: 'Pemindahan Bank',
  bank_name: 'Nama Bank',
  account_name: 'Nama Akaun',
  account_number: 'Nombor Akaun',
  pix_number: 'Nombor PIX',
  drop_or_select: 'Seret atau Pilih fail',
  drop_files_here: 'Seret fail ke sini',
  transfer_to: 'Pindahkan Ke',
  upload_receipt: 'Muat Naik Resit',
  select_bonus: 'Pilih Bonus',
  no_promotion: 'Tiada Promosi',
  please_upload_receipt: 'Sila muat naik resit',
  bank_account: 'Akaun Bank',
  bank_branch: 'Cawangan Bank',
  pay_id: 'ID Pembayaran',
  add_account: 'Tambah Akaun',
  account_holder_name: 'Nama Pemegang Akaun',
  name_of_the_account_holder: 'Nama Pemegang Akaun',
  fill_in_bank_account: 'Isikan Akaun Bank',
  fill_in_pay_id: 'Isikan ID Pembayaran',
  fill_in_bank_branch: 'Isikan Cawangan Bank',
  withdraw_information: 'Maklumat Pengeluaran',
  note: 'Nota',
  amount_withdrawn: 'Jumlah yang dikeluarkan',
  amount_no_exceed_balance: 'Jumlah Pengeluaran tidak boleh melebihi baki yang ada',
  minimum_withdraw_amount_is_x: 'Jumlah Pengeluaran minimum adalah {{x}}',
  transaction_history: 'Sejarah Transaksi',
  date_from: 'Tarikh Dari',
  date_to: 'Tarikh Hingga',
  account_name_must_be_4: 'Nama Akaun mesti lebih daripada 4 panjangnya',
  account_number_must_be_x: 'Nombor akaun mesti 10-14 panjangnya',
  minimum_length_is_x: 'Panjang minimum adalah {{x}}',
  maximum_length_is_x: 'Panjang maksimum adalah {{x}}',
  payment_method: 'Kaedah Pembayaran',
  mode: 'Mod',
  no_bank_account: 'Anda tidak mempunyai akaun untuk pengeluaran, Tambah sekarang',
  please_select_bank: 'Sila pilih Akaun Bank',
  bet_history: 'Sejarah Pertaruhan',
  game_provider: 'Penyedia Permainan',
  bet_count: 'Jumlah Pertaruhan',
  win_loss: 'Menang/Kalah',
  rebate: 'Rebat',
  turnover: 'Perolehan',
  game_category: 'Kategori Permainan',
  you_have_x_unread_messages: 'Anda mempunyai {{x}} mesej yang belum dibaca',
  deposit_amount_between_x_and_y: 'Jumlah deposit mestilah antara {{x}} dan {{y}}',
  minimum_amount_is_x_y: 'Jumlah minimum adalah {{x}}{{y}}',
  all: 'Semua',
  title: 'Tajuk',
  date: 'Tarikh',
  type: 'Jenis',
  status: 'Status',
  remark: 'Komen',
  category: 'Kategori',
  please_select_x: 'Sila pilih {{x}}',
  save: 'Simpan',
  change_password: 'Tukar Kata Laluan',
  current_password: 'Kata Laluan Semasa',
  confirm_password: 'Sahkan Kata Laluan Baru',
  submit: 'Hantar',
  password_changed: 'Kata Laluan Ditukar',
  x_must_be_y_characters: '{{x}} mesti sekurang-kurangnya {{y}} aksara',
  reset_password: 'Set Semula Kata Laluan',
  casino: 'Kasino',
  slots: 'Slot',
  live_casino: 'Kasino Langsung',
  fishing: 'Memancing',
  sports: 'Sukan',
  cards: 'Kad',
  clear: 'Bersihkan',
  search: 'Cari',
  new: 'Baru',
  logout: 'Log Keluar',
  choose_date: 'Pilih Tarikh',
  select_both_date: 'Pilih kedua-dua tarikh Dari dan Ke',
  from: 'Dari',
  to: 'Ke',
  apply: 'Memohon',
  promotions: 'Promosi',
  learn_more: 'KETAHUI LEBIH LANJUT',
  password_doesnt_match: 'Kata laluan tidak sepadan',
  cancel: 'Batal',
  confirm_to_pay: 'Sahkan Untuk Bayar',
  deposit_amount: 'Jumlah Deposit',
  loading_please_wait: 'Memuatkan, Sila Tunggu',
  account: 'Akaun',
  profile: 'Profil',
  bank_list: 'Senarai Bank',
  in_maintenance: 'Dalam Penyelenggaraan',
  birthday: 'Hari Lahir',
  birthday_bonus: 'Masukkan tarikh lahir anda untuk bonus! 🎉🎁',
  referral_code: 'Kod Rujukan',
  friend_referral_code: 'Kode kawan rekeman',
  no_friend: '( kalau tak ada, tak perlu isi )',
  referral: 'Rujukan',
  my_referral: 'Rujukan Saya',
  my_claim: 'Tuntutan Saya',
  claim_profit: 'TUNTUT KEUNTUNGAN',
  available_profit: 'Keuntungan Tersedia',
  claimed_profit: 'Keuntungan Dituntut',
  all_time_profit: 'Keuntungan Sepanjang Masa',
  referral_note_on_x: 'Dapatkan keuntungan setiap kali rakan anda bermain di {{x}}',
  tier: 'Tahap',
  commission: 'Komisen',
  referred_member: 'Ahli Dirujuk',
  profit_earned: 'Keuntungan Diperoleh',
  account_information: 'Maklumat Akaun',
  invite_friends: 'Jemput Rakan',
  how_it_work: 'Bagaimana ia berfungsi?',
  invite_note: 'Dapatkan bonus rujukan eksklusif apabila rakan anda mendaftar menggunakan kod rujukan anda!',
  share_via: 'Kongsi melalui',
  referral_link: 'Pautan Rujukan',
  total_profit: 'Jumlah Keuntungan',
  copied: 'Disalin',
  nothing_to_claim: 'Tiada apa yang perlu dituntut',
  claim_history: 'Sejarah Tuntutan',
  downline: 'Kumpulan Saya',
  last_x_digit_bank_ref_number: 'Nombor rujukan bank terakhir {{x}} digit',
  bank_ref_number_validation_x: 'Nombor Rujukan Bank diperlukan dan mestilah {{x}} aksara panjang',
  sort: 'Isih',
  login_directly: 'Login terus dengan',
  our_game_provides: 'PENYEDIA PERMAINAN KAMI',
  refer_and_reward: 'Rujuk & Ganjaran',
  linked_successfully_x: 'Berjaya dihubungkan dengan {{x}}! Sila daftar akaun anda untuk kali pertama',
  more_x_of_y: 'Lebih {{x}}/{{y}}',
  default: 'Lalai',
  promotion: 'Promosi',
  daily_mission: 'Misi Harian',
  agent: 'Ejen',
  contact_us: 'Hubungi Kami',
  download: 'Muat turun',
  cash_claim: 'Tuntut Tunai',
  demo: 'Demo',
  favorites: 'Kegemaran',
  popular: 'Popular',
  claim_bonus: 'Tuntut Bonus',
  insufficient_balance: 'Baki Tidak Mencukupi',
  currently_displaying: 'Kini memaparkan {{x}} permainan {{y}} daripada {{z}}',
  all_x: 'Semua {{x}}',
  no_results: 'Tiada Hasil Ditemui',
  search_games: 'Cari Permainan',
  please_scan_using_your: 'Sila imbas menggunakan',
  vip_level: 'Tahap VIP',
  bonus_direct: 'BONUS LANGSUNG',
  rebate_level: 'Tahap Rebat',
  vip_header:
    'Semua pertaruhan yang diletakkan pada Mesin Slot, Permainan Papan dan Kad, Memancing, Video, Sukan dan Permainan Loteri di Dewakaya77 akan dikumpul secara tetap. Pertaruhan yang sah = Pendapatan Tetap dan Kekayaan Wang!',
  vip_example:
    'Contoh 1: Ahli yang mencapai 1 Juta dalam deposit terkumpul, mencapai Tahap VIP 2, menerima bonus sebanyak R$210, dan Rebat 3.50%, Apabila mereka mencapai 6 Juta dalam deposit terkumpul, mereka mencapai Tahap VIP 3 dan menerima bonus sebanyak R$800, dengan Rebat 4%',
  activity_rules: 'Peraturan Aktiviti:',
  activity_rules_1: '1. Semua bonus memerlukan Rollover x3 untuk memohon Pengeluaran',
  activity_rules_2:
    '2. Sila tuntut Bonus Rebat dalam tempoh yang ditetapkan, jika anda terlambat, ia akan dianggap sebagai pengabaian;',
  activity_rules_3:
    '3. Penyertaan ahli dalam aktiviti dikira secara automatik oleh sistem, jika terdapat sebarang bantahan, hasil penyiasatan Dewakaya77 akan dipatuhi;',
  activity_rules_4:
    '4. Jika anda terlupa Akaun/Kata Laluan ahli, sila hubungi "Khidmat Pelanggan Dalam Talian 24-jam" untuk membantu anda memulihkan maklumat akaun anda;',
  activity_rules_5:
    '5. Dewakaya77 berhak untuk mengubah, menghentikan atau membatalkan promosi ini pada bila-bila masa.',
  activity_rules_6: '6. Jangan simpan permainan percuma, Beli permainan percuma',
  activity_rules_7: '7. Tidak boleh digabungkan dengan mana-mana bonus',
  activity_rules_8: '8. Pengeluaran Maksimum setiap Deposit ialah R$100.000.00',
  event_instructions: 'Arahan Acara',
  agent_p1: 'Kongsi pautan unik anda di aplikasi dan media sosial',
  agent_p2:
    'Facebook, Instagram, Tiktok, Telegram, WhatsApp, YouTube, dan lain-lain, cepat dan jemput semua orang, ahli yang mendaftar melalui pautan eksklusif anda akan menjadi ejen langsung anda. Tetapi perhatikan kerana ahli baru yang anda jemput mesti asli dan sah.',
  agent_step1:
    'Dapatkan jemputan rakan, deposit pertama berjaya, anda boleh memenangi bonus sebanyak R$5. Sebagai contoh, jika anda menjemput 10 orang untuk deposit pertama, anda boleh memperoleh R$50. Semakin ramai orang yang anda jemput, semakin banyak bonus yang anda terima. Anda boleh melihat sejarah anda dan mendaftar di halaman promosi.',
  agent_step2:
    'Jika tetamu anda bertaruh R$1 atau lebih, anda sudah boleh menikmati komisen tinggi! Anda boleh mendapat sehingga 5% komisen seumur hidup, dan jemputan seumur hidup! Sistem akan menyelesaikan komisen hari sebelumnya pada pukul 7 pagi hari berikutnya.',
  agent_step2_1: 'Cara untuk mendapatkan: Log masuk - Promosi jemputan - Kumpul komisen',
  agent_table1: 'Kadar komisen pertaruhan untuk slot, memancing dan blockchain adalah seperti berikut:',
  agent_table1_1: 'Pelanggan tambah nilai 20 bertaruh 200',
  person: 'orang',
  agent_table2_title1: 'Pertaruhan Ahli',
  agent_table2_title2: 'Hanya Permainan Slot',
  agent_table2_example:
    'Contoh: Anda mempunyai 100 ahli, dan jumlah nilai sebenar pertaruhan dianggarkan 1 juta reais sehari. Komisen yang anda terima pada hari itu adalah: 1,000,000 x 1.20% 12,000 reais. Pendapatan bulanan 100,000 adalah sangat mudah dicapai.',
  reminder: 'Peringatan',
  agent_reminder:
    'Akaun ahli dengan IP yang sama, peranti yang sama, nama, kad bank dan maklumat lain hanya boleh menyertai sekali, dan sesiapa yang melanggar peraturan tidak akan mendapat manfaat daripada bonus ini. atau kumpulan itu akan dirampas dan akaun akan dibekukan secara kekal',
  agent_step3_1:
    'Penyertaan ahli dalam aktiviti dikira secara automatik oleh sistem, jika terdapat sebarang bantahan, keputusan analisis yang dijalankan oleh Dewakaya77 akan dipatuhi',
  agent_step3_2:
    'Jika anda terlupa akaun/kata laluan ahli anda, sila hubungi pasukan khidmat pelanggan kami 24-jam untuk membantu anda memulihkan maklumat akaun anda',
  agent_step3_3: 'Dewakaya77 berhak untuk mengubah, mengganggu atau membatalkan promosi pada bila-bila masa',
  agent_step3_4: 'Ejen boleh menerima bonus komisen dalam bentuk tunai atau kredit dalam dompet',
  agent_step3_5: 'Jangan simpan permainan percuma, Beli permainan percuma',
  agent_step3_6: 'Tidak boleh digabungkan dengan mana-mana bonus dan deposit',
  agent_step3_7: 'Hanya tersedia untuk permainan Slot',
  promo_concluded: 'Promo telah tamat! Sila semak kembali esok!',
  can_only_claim: 'Boleh menuntut bonus antara {{x}} hingga {{y}} Masa Brazil',
  please_login: 'Sila Log Masuk terlebih dahulu untuk menuntut bonus!',
  back_to_home: 'Kembali ke Utama',
  bonus_claimed: 'Bonus Telah Dituntut!',

  network_error: 'Ralat Rangkaian',
  we_have_sent_otp: 'Kami telah menghantar OTP ke nombor telefon anda',
  your_otp_expire: 'OTP anda akan tamat tempoh dalam',
  didnt_receive: 'Tidak menerima mesej?',
  send_again: 'Hantar Semula',
  resend_again: 'Hantar semula dalam',
  invalid_verification: 'Kod Pengesahan Tidak Sah!',
  otp_expired: 'OTP tamat tempoh, sila masukkan semula nombor telefon anda dan cuba lagi!',
  otp_expired_redirect: 'OTP tamat tempoh! mengalihkan ke log masuk',
  captcha_check_failed: 'Pemeriksaan Captcha Gagal!',
  verify_phonenumber: 'Sahkan Nombor Telefon',
  send_otp: 'Hantar OTP',
  success_verification: 'Nombor Telefon Anda Telah Disahkan!',
  press_get_code: 'Tekan Butang DAPATKAN KOD Untuk Menerima Kod Pengesahan Melalui SMS',
  otp_sent: 'Kod Pengesahan Dihantar! Masukkan kod pengesahan di bawah untuk meneruskan',
  get_code: 'Dapatkan Kod',
  verify: 'Sahkan',
  verification_code: 'Kod Pengesahan',
  otp_not_found: 'Fungsi OTP Tidak Ditemui!',
  yes: 'Ya',
  no: 'Tidak',
  please_register_to_claim_bonus: 'Sila Daftar untuk menuntut bonus!',
  home: 'Utama',
  history: 'Sejarah',
  live_transactions: 'Transaksi Langsung',
  live: 'Langsung',
  x_categories: '{{x}} Kategori',
  x_category: '{{x}} Kategori',
  live_chat: 'Sembang Langsung',
  invite_friend: 'Jemput Rakan',
  copy_referral_link: 'Salin Pautan Rujukan',
  balance: 'Baki',
  level: 'Tahap',
  refresh: 'Segarkan',
  password: 'Kata Laluan',
  password_placeholder: '6 - 20 aksara',
  optional: '(pilihan)',
  login_here: 'Log Masuk Di Sini',
  enter_text_on_the_left: 'Masukkan teks di sebelah kiri',
  more_info: 'Maklumat Lanjut',
  back: 'Kembali',
  number_exists: 'Nombor telefon ini sudah didaftarkan!',
  number_not_exists: 'Nombor telefon ini tidak didaftarkan!',
  please_download_x_to_play: 'Sila muat turun {{x}} untuk bermain',
  play_now: 'Main Sekarang',
};

export default ms;
