import React, { useState, useEffect, useRef } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';
// @mui
import { Box, styled } from '@mui/material';
import ReactPixel from 'react-facebook-pixel';

// hooks
import useSettings from '../../hooks/useSettings';
import useCollapseDrawer from '../../hooks/useCollapseDrawer';
// config
import { HEADER } from '../../config';
// redux
import { useDispatch, useSelector } from '../../redux/store';
import useAuth from '../../hooks/useAuth';

import AccountInfo from '../../views/user/AccountInfo';

import DashboardHeader from './header';

import {
  getAngPauPromotion,
  getGameCategsAndCompanies,
  getSiteSettings,
  getUserMemberBalance,
} from '../../redux/slices/lookup';

import useLocales from '../../hooks/useLocales';

import { setGameCategories, setAllGames, setActiveTab } from '../../redux/slices/game';

import conObj from '../../utils/connection-assistant';

import { usePageVisibility } from '../../hooks/usePageVisibility';
import WithdrawFormDialog from '../../views/withdraw/WithdrawFormDialog';
import BottomMenu from './navbar/BottomMenu';
import { closeLoadingScreen, openLoadingScreen } from '../../redux/slices/settings';

// ----------------------------------------------------------------------

const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  height: '100%',
  // backgroundImage: `url(${require('../../assets/bg.png')})`,
  // backgroundSize: 'cover',
  // backgroundAttachment: 'fixed',
  // backgroundRepeat: 'no-repeat',
  // backgroundPosition: 'bottom',
  minHeight: '100vh',
  paddingBottom: HEADER.MOBILE_HEIGHT,
  [theme.breakpoints.up('lg')]: {
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
  [theme.breakpoints.down('lg')]: {
    paddingBottom: HEADER.MOBILE_HEIGHT,
  },
}));

// ----------------------------------------------------------------------

const DashboardLayout = () => {
  const { isAuthenticated } = useAuth();

  const { collapseClick } = useCollapseDrawer();

  const { isAddMemberBankDialog } = useSelector((x) => x.addMemberBankDialog);

  const { siteSettings } = useSelector((x) => x.lookup);

  const isVisible = usePageVisibility();

  const { translate } = useLocales();

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { themeLayout } = useSettings();

  const [menuIndex, setMenuIndex] = useState(1);

  const intervalRef = useRef(null);

  const verticalLayout = themeLayout === 'vertical';

  useEffect(() => {
    const promises = [getGameCategsAndCompanies(), getSiteSettings(), getAllGames(), getAngPauPromotion()];

    if (isAuthenticated) {
      promises.push(getUserMemberBalance());
    } else if (localStorage.getItem('referralCode')) navigate('/auth/register-unprotected');

    dispatch(openLoadingScreen());
    Promise.all(promises).finally(() => dispatch(closeLoadingScreen()));
  }, [isAuthenticated]);

  /**
   * Call getUserMemberBalance() when tab is active and isAuthenticated
   * to keep the member balance updated
   */
  useEffect(() => {
    if (isVisible && isAuthenticated) {
      getUserMemberBalance();
      intervalRef.current = setInterval(() => {
        getUserMemberBalance();
      }, 30000);
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
    }
  }, [isVisible, isAuthenticated]);

  // useEffect(() => {
  //   console.log(openPageLoadingCount);
  // }, [openPageLoadingCount])

  useEffect(() => {
    try {
      if (siteSettings?.config_analytics) {
        const _ga = siteSettings?.config_analytics?.split(',');

        // Multiple products (previously known as trackers)
        ReactGA.initialize(
          _ga.map((item) => ({
            // measurement ID
            trackingId: item,
            // gaOptions: {...}, // optional
            // gtagOptions: {...}, // optional
          }))
          //   [
          //   {
          //     // measurement ID
          //     trackingId: _ga[0],
          //     // gaOptions: {...}, // optional
          //     // gtagOptions: {...}, // optional
          //   },
          // ]
        );
      }

      if (siteSettings?.config_fb_pixel) {
        const metaPixel = siteSettings.config_fb_pixel.replace(/[^\d,]+/g, '').split(',');
        const options = {
          autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
          debug: false, // enable logs
        };

        metaPixel.forEach((_pixel) => {
          ReactPixel.init(_pixel, options);

          ReactPixel.pageView(); // For tracking page view
        });
      }

      if (siteSettings?.config_livechat) {
        const respondIo = JSON.parse(siteSettings?.config_livechat);
        const existingScript = document.getElementById(respondIo?.id);

        if (!existingScript) {
          const script = document.createElement('script');
          script.id = respondIo?.id;
          script.src = respondIo?.src;
          script.async = true;

          document.head.appendChild(script);
        }
      }
    } catch (error) {
      console.warn(`3RD PARTY INTEGRATION ERROR: ${error?.message}`);
    }
  }, [siteSettings]);

  const getAllGames = () => {
    conObj.post('games/all.json?limit=1').then((res) => {
      if (res.status === 200) {
        // res.data?.data?.data
        // set appversion to localstorage
        // res.data?.data.appversion
        const _allGames = res.data?.data?.data;
        dispatch(setAllGames(_allGames));
        dispatch(
          setGameCategories(
            _allGames
              .filter((f) => f.key.toLowerCase().indexOf('gcat') !== -1)
              .map((item) => ({ key: item.key, name: item.name, slug: item.slug }))
          )
        );
      }
    });
  };

  const [openSettings, setOpenSettings] = useState(false);

  const handleOpen = () => {
    setOpenSettings(!openSettings);
  };

  const handleClose = () => {
    setOpenSettings(false);
  };

  if (verticalLayout) {
    return (
      <>
        <Box
          component="main"
          sx={{
            px: { lg: 2 },
            pt: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 80}px`,
            },
            pb: {
              xs: `${HEADER.MOBILE_HEIGHT + 24}px`,
              lg: `${HEADER.DASHBOARD_DESKTOP_HEIGHT + 24}px`,
            },
          }}
        >
          <Outlet />
        </Box>
      </>
    );
  }

  return (
    <Box
      sx={{
        display: { lg: 'block' },
        backgroundImage: `url(${require('../../assets/bg.png')})`,
        backgroundRepeat: 'repeat-y',
        backgroundSize: 'cover',
      }}
    >
      <DashboardHeader />
      <MainStyle collapseClick={collapseClick}>
        {/* <img src={require('../../assets/bg.png')} style={{ position: 'absolute' }} alt="" /> */}
        <Outlet />
      </MainStyle>

      <BottomMenu
        isAuthenticated={isAuthenticated}
        dispatch={dispatch}
        handleOpen={handleOpen}
        menuIndex={menuIndex}
        navigate={navigate}
        setActiveTab={setActiveTab}
        setMenuIndex={setMenuIndex}
        translate={translate}
      />

      {/* <Box
        sx={{
          borderTop: '1px solid #dd0000',
          width: '100%',
          height: 'auto',
          // backgroundColor: palette.bottomMenu.bgColor,
          position: 'fixed',
          bottom: 0,
          left: 0,
          zIndex: 100,
          boxShadow: '0 1px 2px -2px rgba(0,0,0,.64),0 3px 6px 0 rgba(0,0,0,.48),0 5px 12px 4px rgba(0,0,0,.36)',
          display: 'block',
          p: '5px 0',
          right: 0,
        }}
      >
        <Grid container>
          <Grid
            onClick={() => {
              setMenuIndex(1);
              dispatch(setActiveTab('Popular'));
              navigate('/');
            }}
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 1 ? '#34282C' : '#000'}`,
            }}
          >
            <Image
              src={require('../../assets/menu/home_icon.png')}
              sx={{
                width: '40px',
                height: '40px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>HOME</BottomMenuLabel>
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'end',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 2 ? '#34282C' : '#000'}`,
            }}
            onClick={() => {
              setMenuIndex(2);
              if (!isAuthenticated) {
                navigate('/auth/login-unprotected');
              } else {
                navigate('/deposit');
              }
            }}
          >
            <Image
              src={require('../../assets/menu/deposit_icon.png')}
              sx={{
                width: '40px',
                height: '40px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>DEPOSIT</BottomMenuLabel>
          </Grid>

          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 3 ? '#34282C' : '#000'}`,
            }}
            onClick={() => {
              setMenuIndex(3);
              navigate('/promotion');
            }}
          >
            <Image
              sx={{
                width: '60px',
                height: '44px',
                cursor: 'pointer',
              }}
              src={require('../../assets/menu/bonus_icon.gif')}
              alt="Loading"
            />
          </Grid>
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'end',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 4 ? '#34282C' : '#000'}`,
            }}
            onClick={() => {
              setMenuIndex(4);
              if (!isAuthenticated) {
                navigate('/auth/login-unprotected');
              } else {
                navigate('/withdraw');
              }
            }}
          >
            <Image
              src={require('../../assets/menu/withdraw_icon.png')}
              sx={{
                width: '40px',
                height: '40px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>WITHDRAW</BottomMenuLabel>
          </Grid>
          <Grid
            item
            xs={12 / 5}
            sx={{
              display: 'flex',
              alignItems: 'center',
              // justifyContent: 'end',
              cursor: 'pointer',
              flexDirection: 'column',
              backgroundColor: `${menuIndex === 5 ? '#34282C' : '#000'}`,
            }}
            onClick={() => {
              if (!isAuthenticated) {
                navigate('/auth/login-unprotected');
              } else {
                setMenuIndex(5);
                handleOpen();
              }
            }}
          >
            <Image
              src={require('../../assets/menu/settings_icon.png')}
              sx={{
                width: '40px',
                height: '40px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>SETTINGS</BottomMenuLabel>
          </Grid>
        </Grid>
      </Box> */}

      {openSettings && <AccountInfo open={openSettings} handleClose={handleClose} />}

      {isAddMemberBankDialog?.open && <WithdrawFormDialog />}
    </Box>
  );
};

export default DashboardLayout;
