// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const cn = {
  game: '游戏',
  games: '游戏',
  sport: '运动',
  center: '中心',
  mines: '地雷',
  crash: '坠毁',
  lucky_colour: '幸运颜色',
  dice: '骰子',
  limbo: '中途',
  lucky_gift: '幸运礼物',
  all_games: '所有游戏',
  game_history: '游戏历史',
  coupon_code: '优惠码',
  telegram: '电报',
  customer_service: '客户服务',
  player: '玩家',
  winner: '获胜者',
  all_bets: '所有投注',
  my_bets: '我的投注',
  name: '名字',
  time: '时间',
  bet_amount: '投注金额',
  prediction: '预测',
  payout: '支付',
  login: '登录',
  register: '注册',
  username_placeholder: '用户名',
  please_input_your_x: '请输入您的{{x}}',
  captcha: '验证码',
  forgot_password: '忘记密码',
  minor_note: '访问此网站，请确保您已年满18岁并同意',
  terms_of_service: '服务条款',
  by_signing_in: '登录或注册即表示您同意我们的',
  dont_have_account: '还没有账号？',
  sign_up: '注册',
  username: '用户名',
  email: '电子邮件',
  full_name: '全名',
  confirm_18: '我确认我已年满18岁并且我已阅读',
  already_have_account: '已有账户？',
  share: '分享',
  deposit: '存款',
  bonus: '奖金',
  app: '应用程序',
  load_more: '加载更多',
  details: '详情',
  helps: '帮助',
  kyc_policy: 'KYC 政策',
  privacy_policy: '隐私政策',
  responsible_gaming: '负责任的游戏',
  terms_and_condition: '条款和条件',
  faq: '常见问题',
  about_us: '关于我们',
  amount: '金额',
  extra: '额外',
  deposit_bonus: '存款奖金',
  do_not_participate: '不参与促销活动',
  payment_problem: '付款问题',
  click_for_help: '点击寻求帮助',
  deposit_notes: '存款说明',
  available_balance: '可用余额',
  withdraw: '取款',
  withdrawal_notes: '取款说明',
  withdrawal_note1: '当前的取款次数',
  withdrawal_note1_1: 'VIP',
  withdrawal_note2: '取款费用：',
  min: '最小值：',
  max: '最大值：',
  opening_times: '开放时间：',
  withdrawal_note3: '1.最快取款时间在10分钟内',
  withdrawal_note4: '2.确保您的取款信息准确无误，如果取款信息错误，可能会导致资金损失。',
  withdrawal_note5: '3.如果有任何问题，请随时联系我们。',
  notifications: '通知',
  invalid_x: '无效的{{x}}',
  captcha_not_match: '验证码不匹配',
  invalid_x_must_be_atleast_y_characters: '无效的{{x}}，必须至少{{y}}个字符',
  x_is_required: '{{x}}是必需的',
  phone_number: '电话号码',
  phone_num_x_max: '电话号码最多{{x}}位数',
  email_sent: '邮件已发送，请检查您的电子邮件。',
  enter_valid_email: '请输入有效的电子邮件。',
  confirm: '确认',
  password_atleast_6_characters: '密码必须至少6个字符。',
  password_reset_success: '密码重置成功！',
  new_password: '新密码',
  bank: '银行',
  bank_transfer: '银行转账',
  bank_name: '银行名称',
  account_name: '账户名称',
  account_number: '账户号码',
  pix_number: 'PIX 号码',
  drop_or_select: '拖放或选择文件',
  drop_files_here: '将文件拖放到此处',
  transfer_to: '转账至',
  upload_receipt: '上传收据',
  select_bonus: '选择奖金',
  no_promotion: '没有促销',
  please_upload_receipt: '请上传收据',
  bank_account: '银行账户',
  bank_branch: '银行分支',
  pay_id: '支付 ID',
  add_account: '添加账户',
  account_holder_name: '账户持有人姓名',
  name_of_the_account_holder: '账户持有人姓名',
  fill_in_bank_account: '填写银行账户',
  fill_in_pay_id: '填写支付 ID',
  fill_in_bank_branch: '填写银行分支',
  withdraw_information: '取款信息',
  note: '备注',
  amount_withdrawn: '取款金额',
  amount_no_exceed_balance: '取款金额不得超过可用余额',
  minimum_withdraw_amount_is_x: '最低取款金额为 {{x}}',
  transaction_history: '交易记录',
  date_from: '开始日期',
  date_to: '结束日期',
  account_name_must_be_4: '账户名称必须大于 4 个字符',
  account_number_must_be_x: '账户号码长度必须在 10 到 14 位之间',
  minimum_length_is_x: '最小长度为 {{x}}',
  maximum_length_is_x: '最大长度为 {{x}}',
  payment_method: '支付方式',
  mode: '模式',
  no_bank_account: '您没有任何可以取款的账户，请立即添加',
  please_select_bank: '请选择一个银行账户',

  bet_history: '投注历史',
  game_provider: '游戏供应商',
  bet_count: '投注次数',
  win_loss: '胜负',
  rebate: '返现',
  turnover: '营业额',
  game_category: '游戏类别',

  you_have_x_unread_messages: '您有 {{x}} 条未读消息',
  deposit_amount_between_x_and_y: '存款金额必须在 {{x}} 和 {{y}} 之间',
  minimum_amount_is_x_y: '最低金额为 {{x}}{{y}}',

  all: '所有',
  title: '标题',
  date: '日期',
  type: '类型',
  status: '状态',
  remark: '备注',
  category: '类别',
  please_select_x: '请选择 {{x}}',
  save: '保存',

  change_password: '更改密码',
  current_password: '当前密码',
  confirm_password: '确认新密码',
  submit: '提交',
  password_changed: '密码已更改',
  x_must_be_y_characters: '{{x}} 必须至少 {{y}} 个字符',
  reset_password: '重置密码',
  casino: '赌场',
  slots: '老虎机',
  live_casino: '真人赌场',
  fishing: '钓鱼',
  sports: '体育',
  cards: '卡牌',
  clear: '清除',
  search: '搜索',
  new: '新',
  logout: '登出',
  choose_date: '选择日期',
  select_both_date: '选择开始日期和结束日期',
  from: '从',
  to: '至',
  apply: '应用',
  promotions: '促销活动',
  learn_more: '了解更多',
  password_doesnt_match: '密码不匹配',
  cancel: '取消',
  confirm_to_pay: '确认付款',
  deposit_amount: '存款金额',
  loading_please_wait: '加载中，请稍候',
  account: '账户',
  profile: '个人资料',
  bank_list: '银行列表',
  in_maintenance: '维护中',
  birthday: '生日',
  birthday_bonus: '输入您的生日以获取奖金！🎉🎁',
  referral_code: '推荐码',
  friend_referral_code: '好友推荐码',
  no_friend: '(如果没有好友，请留空)',
  referral: '推荐',
  my_referral: '我的推荐',
  my_claim: '我的领取',
  claim_profit: '领取利润',
  available_profit: '可用利润',
  claimed_profit: '已领取利润',
  all_time_profit: '历史利润',
  referral_note_on_x: '每次您的朋友在 {{x}} 玩游戏时，您都会赚取利润',
  tier: '级别',
  commission: '佣金',
  referred_member: '推荐会员',
  profit_earned: '赚取利润',
  account_information: '账户信息',
  invite_friends: '邀请朋友',
  how_it_work: '如何运作？',
  invite_note: '当您的朋友使用您的推荐码注册时，您将获得独家推荐奖金！',
  share_via: '通过以下方式分享',
  referral_link: '推荐链接',
  total_profit: '总利润',
  copied: '已复制',
  nothing_to_claim: '没有可领取的奖金',
  claim_history: '领取历史',
  downline: '我的下线',
  last_x_digit_bank_ref_number: '银行参考号的最后 {{x}} 位数字',
  bank_ref_number_validation_x: '银行参考号是必填项，必须为 {{x}} 位字符',
  sort: '排序',
  login_directly: '直接使用登录',
  our_game_provides: '我们的游戏供应商',
  refer_and_reward: '推荐与奖励',
  linked_successfully_x: '与 {{x}} 成功关联！请首次注册您的账户',
  more_x_of_y: '更多 {{x}}/{{y}}',
  default: '默认',
  promotion: '促销',
  daily_mission: '每日任务',
  agent: '代理',
  contact_us: '联系我们',
  download: '下载',
  cash_claim: '现金领取',
  demo: '演示',
  favorites: '收藏',
  popular: '热门',
  claim_bonus: '领取奖金',
  insufficient_balance: '余额不足',
  currently_displaying: '当前显示 {{x}} {{y}} 个游戏，总共有 {{z}} 个',
  all_x: '所有 {{x}}',
  no_results: '没有找到结果',
  search_games: '搜索游戏',
  please_scan_using_your: '请使用您的',
  vip_level: 'VIP 级别',
  bonus_direct: '直接奖金',
  rebate_level: '返点级别',
  vip_header:
    '在 Dewakaya77 上，所有的老虎机、桌面和纸牌游戏、捕鱼、视频、体育和彩票游戏的投注将永久累积。有效投注 = 永久收入和财富！',
  vip_example:
    '示例 1: 达到 100 万累计存款的会员，达到 VIP 2 级别，获得 R$210 的奖金和 3.50% 的返点。当他们累计存款达到 600 万时，达到 VIP 3 级别，获得 R$800 的奖金和 4% 的返点。',
  activity_rules: '活动规则：',
  activity_rules_1: '1. 所有奖金需满足 x3 的流水要求才能申请提现',
  activity_rules_2: '2. 请在截止日期内领取返点奖金，若迟到，将视为放弃；',
  activity_rules_3: '3. 会员参加活动的情况由系统自动统计，如有异议，以 Dewakaya77 的调查结果为准；',
  activity_rules_4: '4. 如果忘记会员账户/密码，请联系“24 小时在线客服”以帮助您找回账户信息；',
  activity_rules_5: '5. Dewakaya77 保留随时更改、停止或取消此促销的权利。',
  activity_rules_6: '6. 不允许保存免费游戏，购买免费游戏',
  activity_rules_7: '7. 不能与任何奖金叠加',
  activity_rules_8: '8. 每次存款的最高提现金额为 R$100,000.00',
  event_instructions: '活动说明',
  agent_p1: '在应用程序和社交媒体上分享您的唯一链接',
  agent_p2:
    'Facebook、Instagram、Tiktok、Telegram、WhatsApp、YouTube 等等，快来邀请大家，通过您的专属链接注册的会员将成为您的直接代理。但请注意，您邀请的新会员必须是真实有效的。',
  agent_step1:
    '邀请好友首次存款成功后，您可以赢得 R$5 的奖金。例如，如果您邀请 10 个人进行首次存款，您可以赚取 R$50。邀请的人越多，您获得的奖金就越多。您可以查看您的历史记录并注册以领取奖金和高佣金。',
  agent_step2:
    '如果您的客户投注 R$1 或更多，您就可以享受高佣金！您可以获得最高 5% 的终身佣金，并且终身邀请！系统将在第二天早上 7 点结算前一天的佣金。',
  agent_step2_1: '如何获取：登录 - 邀请推广 - 领取佣金',
  agent_table1: '老虎机、捕鱼和区块链的投注佣金率如下：',
  agent_table1_1: '客户充值 20，投注 200',
  person: '会员',
  agent_table2_title1: '会员投注',
  agent_table2_title2: '仅限老虎机游戏',
  agent_table2_example:
    '示例：您有 100 位会员，总投注额估计为每天 100 万雷亚尔。您当天收到的佣金为：1,000,000 x 1.20% = 12,000 雷亚尔。每月收入 100,000 非常容易实现。',
  reminder: '提醒',
  agent_reminder:
    '具有相同 IP、相同设备、姓名、银行卡及其他信息的会员账户只能参与一次，违反规则的人员将无法获得此奖金。违规者的账户将被没收并永久冻结。',
  agent_step3_1: '会员参与活动的情况由系统自动统计，如有异议，以 Dewakaya77 的分析结果为准。',
  agent_step3_2: '如果您忘记了会员账户/密码，请联系 24 小时客服团队以帮助您找回账户信息。',
  agent_step3_3: 'Dewakaya77 保留随时更改、暂停或取消此促销活动的权利。',
  agent_step3_4: '代理能够收到的佣金奖金为现金或钱包中的积分。',
  agent_step3_5: '不允许保存免费游戏，必须购买免费游戏。',
  agent_step3_6: '不能与任何奖金和存款叠加。',
  agent_step3_7: '仅限老虎机游戏。',
  promo_concluded: '促销已结束！请明天再来查看！',
  can_only_claim: '只能在 {{x}} 到 {{y}} 巴西时间之间领取奖金',
  please_login: '请先登录以领取奖金！',
  back_to_home: '返回主页',
  bonus_claimed: '奖金已领取！',

  network_error: '网络错误',
  we_have_sent_otp: '我们已将 OTP 发送到您的手机号码',
  your_otp_expire: '您的 OTP 将在以下时间过期',
  didnt_receive: '没有收到消息？',
  send_again: '重新发送',
  resend_again: '重新发送时间：',
  invalid_verification: '验证码无效！',
  otp_expired: 'OTP 已过期，请重新输入您的手机号码并重试！',
  otp_expired_redirect: 'OTP 过期！正在重定向到登录页面',
  captcha_check_failed: '验证码检查失败！',
  verify_phonenumber: '验证手机号码',
  send_otp: '发送 OTP',
  success_verification: '您的手机号码已成功验证！',
  press_get_code: '按下 GET CODE 按钮以通过 SMS 接收验证码',
  otp_sent: '验证码已发送！请输入下面的验证码以继续',
  get_code: '获取验证码',
  verify: '验证',
  verification_code: '验证码',
  otp_not_found: '未找到 OTP 功能！',
  yes: '是',
  no: '否',
  please_register_to_claim_bonus: '请注册以领取奖金！',
  home: '首页',
  history: '历史',
  live_transactions: '实时交易',
  live: '直播',
  x_categories: '{{x}} 类别',
  x_category: '{{x}} 类别',
  live_chat: '在线聊天',
  invite_friend: '邀请朋友',
  copy_referral_link: '复制推荐链接',
  balance: '余额',
  level: '级别',
  refresh: '刷新',
  password: '密码',
  password_placeholder: '6 - 20 个字符',
  optional: '(可选)',
  login_here: '在这里登录',
  enter_text_on_the_left: '输入左侧的文字',
  more_info: '更多信息',
  back: '返回',
  number_exists: '此手机号码已注册！',
  number_not_exists: '此手机号码未注册！',
  please_download_x_to_play: '请下载 {{x}} 进行游戏',
  play_now: '立即玩',
};

export default cn;
