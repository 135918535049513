import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#C8FACD',
  light: '#6E9046',
  main: '#6E9046',
  dark: '#007B55',
  darker: '#005249',
};
const SECONDARY = {
  lighter: '#D6E4FF',
  light: '#84A9FF',
  main: '#3366FF',
  dark: '#1939B7',
  darker: '#091A7A',
};
const INFO = {
  lighter: '#D0F2FF',
  light: '#74CAFF',
  main: '#1890FF',
  dark: '#0C53B7',
  darker: '#04297A',
};
const SUCCESS = {
  lighter: '#E9FCD4',
  light: '#AAF27F',
  main: '#54D62C',
  dark: '#229A16',
  darker: '#08660D',
};
const WARNING = {
  lighter: '#FFF7CD',
  light: '#FFE16A',
  main: '#FFC107',
  dark: '#B78103',
  darker: '#7A4F01',
};
const ERROR = {
  lighter: '#FFE7D9',
  light: '#FFA48D',
  main: '#FF4842',
  dark: '#B72136',
  darker: '#7A0C2E',
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8),
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main),
};

const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4'],
};

const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#fff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: '#0d5210',
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: '#fff',
    hoverOpacity: 0.08,
    disabledOpacity: 0.48,
  },
};

const palette = {
  header: {
    bgColor: '#000',
    menuBg: '#000',
    menu: '#fff',
    deposit_btn: {
      bg: '#0047aa',
      color: '#fff',
    },
    login_btn: {
      border: '#f8da4a',
      bg: 'linear-gradient(0deg, rgb(170, 37, 27), rgb(235, 68, 58))',
      color: '#fff',
    },
    register_btn: {
      border: '#f8da4a',
      bg: 'linear-gradient(0deg, rgb(14, 42, 148), rgb(43, 102, 225))',
      color: '#fff',
    },
    balance_button: {
      bg: '#159e1a',
      color: '#fff',
    },
  },

  navBarVertical: {
    bg: 'linear-gradient(0deg, #00e495, #000)',
    menu: {
      bgColor: '#000',
      color: '#fff',
    },
  },

  bottomMenu: {
    bgColor: '#000',
    color: `#fff`,
    depositBg: `linear-gradient(#dd0000 0px, #dd0000 100%)`,
    depositBorder: '#dd0000',
  },

  home: {
    announcement: {
      bg: '#6E9046',
      color: '#fff',
    },
    tab: {
      borderBottom: '#dd0000',
      bgActive: '#dd0000',
      bgInActive: 'transparent',
      textActive: '#fff',
      textInactive: '#dd0000',
    },
    sort: {
      labelColor: '#dd0000',
    },
  },

  footer: {
    bgColor: 'rgb(21, 158, 238, .9)',
  },

  visitor: {
    dialog: {
      title: '#000',
      loginDirectly: '#000',
      footerText: '#fff',
      hr: `#000`,
      otherText: '#000',
      otherTextReg: '#000',
    },
    icon: 'grey',
    input: {
      border: '#dd0000',
      color: '#000',
      placeholder: '#637381',
    },
    link: '#327bff',
    errorText: '#bd0000',
    login: {
      bgImage: 'linear-gradient(to bottom, #4ba614, #008c00)',
      color: '#fff !important',
      border: '1px solid #34740e',
      buttonBg: 'linear-gradient(0deg, #1CBC40 0%, #21592E 100%)',
      registerButtonBg: 'linear-gradient(0deg, #212759 0%, #1CBCB2 100%)',
    },
    register: {
      buttonBg: 'linear-gradient(90deg, rgba(60,104,178,1) 0%, rgba(31,54,110,1) 100%)',
      color: '#fff',
      border: '#41a4ff',
    },
  },

  transactionDialog: {
    bg: '#000',
    border: '#dd0000',
    tab: {
      bg: '#dd0000',
      active: '#6E9046',
      inactive: '#c3c3c3',
      activeTxt: '#fff',
      inactiveTxt: '#858585',
    },
    deposit: {
      copy: '#000',
      inputLabel: '#000',
      smallText: '#000',
      largeText: '#000',
      inputBg: '#dd0000',
      suggestedTopupBg: '#fff',
      suggestedTopupTxt: '#000',
      smallPromoBox: {
        bg: '#005103',
        color: '#fff',
      },
      bonusBox: {
        bg: '#fff',
        color: '#000',
        border: '#dd0000',
      },
      select: {
        bg: '#D3D3D3',
        color: '#000',
        border: '#dd0000',
      },
      selectedBankBox: {
        bg: '#D3D3D3',
        border: '#dd0000',
      },
      depositInputBox: {
        bg: '#fff',
        border: '#dd0000',
      },
      depositInput: {
        color: '#000',
      },
      bankRefNumber: {
        color: '#000',
        border: '#dd0000',
        outlineBg: '#fff',
        placeholder: 'rgba(0, 0, 0, .3)',
      },
      confirmBtn: '#01a101',
      bepositBtnText: '#fff',
      depositAmt: '#fff',
    },
    withdraw: {
      text: '#000',
      suggestedWthdrwBg: '#fff',
      availableBalanceTxt: '#fff',
      withdrawInputBox: {
        bg: '#fff',
        border: '#dd0000',
        shadow: 'rgba(23,125,220,.2)',
      },
      noBankAccount: '#000',
      withdrawText: '#fff',
      confirmBtnBg: '#dd0000',
    },
  },

  light: {
    ...COMMON,
    mode: 'light',
    text: { primary: '#fff', secondary: GREY[600], disabled: GREY[500] },
    background: { paper: '#6E9046', default: '#fff', neutral: GREY[200] },
    action: { active: GREY[600], ...COMMON.action },
    header: {
      bgColor: '#000',
      menuBg: '#000',
      menu: '#fff',
      deposit_btn: {
        bg: '#0047aa',
        color: '#fff',
      },
      login_btn: {
        border: '#f8da4a',
        bg: 'linear-gradient(0deg, rgb(170, 37, 27), rgb(235, 68, 58))',
        color: '#fff',
      },
      register_btn: {
        border: '#f8da4a',
        bg: 'linear-gradient(0deg, rgb(14, 42, 148), rgb(43, 102, 225))',
        color: '#fff',
      },
      balance_button: {
        bg: '#159e1a',
        color: '#fff',
      },
    },

    navBarVertical: {
      bg: 'linear-gradient(0deg, #00e495, #000)',
      menu: {
        bgColor: '#000',
        color: '#fff',
      },
    },

    bottomMenu: {
      bgColor: '#000',
      color: `#fff`,
      depositBg: `linear-gradient(#dd0000 0px, #dd0000 100%)`,
      depositBorder: '#dd0000',
    },

    home: {
      announcement: {
        bg: '#6E9046',
        color: '#fff',
      },
      tab: {
        borderBottom: '#dd0000',
        bgActive: '#dd0000',
        bgInActive: 'transparent',
        textActive: '#fff',
        textInactive: '#dd0000',
      },
      sort: {
        labelColor: '#dd0000',
      },
    },

    footer: {
      bgColor: 'rgb(21, 158, 238, .9)',
    },

    visitor: {
      dialog: {
        title: '#000',
        loginDirectly: '#000',
        footerText: '#fff',
        hr: `#000`,
        otherText: '#fff',
      },
      icon: 'grey',
      input: {
        border: '#000',
        color: '#000',
        placeholder: '#939393',
      },
      link: '#327bff',
      errorText: '#bd0000',
      login: {
        bgImage: 'linear-gradient(to bottom, #4ba614, #008c00)',
        color: '#fff !important',
        border: '1px solid #34740e',
      },
      register: {
        buttonBg: 'linear-gradient(90deg, rgba(60,104,178,1) 0%, rgba(31,54,110,1) 100%)',
        color: '#fff',
        border: '#41a4ff',
      },
    },

    txn: {
      bg: '#000',
      border: '#dd0000',
      inputBg: '#D3D3D3',
      inputLabel: '#000',
      inputColor: '#000',
      tab: {
        bg: '#dd0000',
        active: '#dd0000',
        inactive: '#000',
        activeTxt: '#fff',
        inactiveTxt: '#858585',
      },
      deposit: {
        copy: '#000',
        inputLabel: '#000',
        smallText: '#000',
        largeText: '#000',
        inputBg: '#dd0000',
        tabBg: '#c3c3c3',
        suggestedTopupBg: '#fff',
        suggestedTopupTxt: '#000',
        smallPromoBox: {
          bg: '#005103',
          color: '#fff',
        },
        bonusBox: {
          bg: '#fff',
          color: '#000',
          border: '#dd0000',
        },
        select: {
          bg: '#D3D3D3',
          color: '#000',
          border: '#dd0000',
        },
        selectedBankBox: {
          bg: '#D3D3D3',
          border: '#dd0000',
        },
        depositInputBox: {
          bg: '#fff',
          border: '#dd0000',
        },
        depositInput: {
          color: '#000',
        },
        bankRefNumber: {
          color: '#000',
          border: '#dd0000',
          outlineBg: '#fff',
          placeholder: 'rgba(0, 0, 0, .3)',
        },
        confirmBtn: '#01a101',
        bepositBtnText: '#fff',
        depositAmt: '#fff',
      },
      withdraw: {
        text: '#000',
        suggestedWthdrwBg: '#fff',
        availableBalanceTxt: '#009206',
        withdrawInputBox: {
          bg: '#fff',
          border: '#dd0000',
          shadow: 'rgba(23,125,220,.2)',
        },
        noBankAccount: '#000',
        withdrawText: '#fff',
        confirmBtnBg: 'linear-gradient(to top, #DD0000, #f68282)',
      },
      history: {
        button: {
          primary: 'linear-gradient(to top, #1CBC40, #21592E)',
          secondary: '#919191',
        },
      },
    },
  },
  dark: {
    ...COMMON,
    mode: 'dark',
    text: { primary: '#fff', secondary: GREY[500], disabled: GREY[600] },
    background: { paper: '#000', default: '#000', neutral: GREY[500_16] },
    action: { active: GREY[500], ...COMMON.action },
  },
};

export default palette;
