import { useEffect } from 'react';
import { Box, Grid, Typography, styled } from '@mui/material';
import { useLocation } from 'react-router-dom';

// component
import Image from '../../../components/Image';

const BottomMenuBox = styled(Box)(({ theme }) => ({
  background: '#1F1F1F',
  width: '100%',
  height: 'auto',
  position: 'fixed',
  bottom: 0,
  left: 0,
  zIndex: 100,
  display: 'block',
  padding: '10px 0px 0px',
  right: 0,
}));

const BottomMenuLabel = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  textTransform: 'uppercase',
  [theme.breakpoints.only('xs')]: {
    fontSize: '2.4vw',
  },
  [theme.breakpoints.up('sm')]: {
    fontSize: '.6vw',
  },
}));

const MenuGrid = styled(Grid)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  cursor: 'pointer',
  flexDirection: 'column',
  backgroundColor: '#1F1F1F',
}));

const MenuBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'end',
  flexFlow: 'column',
  padding: '5px 15px',
  borderTopLeftRadius: 8,
  borderTopRightRadius: 8,
  [theme.breakpoints.up('sm')]: {
    maxWidth: 'unset',
  },
  [theme.breakpoints.down('sm')]: {
    maxWidth: '20vw',
  },
}));

const BottomMenu = ({
  isAuthenticated,
  dispatch,
  setMenuIndex,
  setActiveTab,
  navigate,
  menuIndex,
  handleOpen,
  translate,
}) => {
  const location = useLocation();

  useEffect(() => {
    console.log(`location = ${location.pathname}`);
  }, []);

  return (
    <BottomMenuBox>
      <Grid container>
        <MenuGrid
          onClick={() => {
            setMenuIndex(1);
            dispatch(setActiveTab('slots'));
            navigate('/');
          }}
          item
          xs={12 / 5}
        >
          <MenuBox>
            <Image
              src={require('../../../assets/menu/home_icon.png')}
              sx={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>{translate('home')}</BottomMenuLabel>
          </MenuBox>
        </MenuGrid>

        <MenuGrid
          onClick={() => {
            setMenuIndex(2);
            dispatch(setActiveTab('slots'));
            navigate('/history/tab');
          }}
          item
          xs={12 / 5}
        >
          <MenuBox>
            <Image
              src={require('../../../assets/menu/history_icon.png')}
              sx={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>{translate('history')}</BottomMenuLabel>
          </MenuBox>
        </MenuGrid>

        <MenuGrid
          item
          xs={12 / 5}
          onClick={() => {
            setMenuIndex(3);
            navigate('/promotion');
          }}
        >
          <MenuBox>
            <Image
              sx={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
              src={require('../../../assets/menu/promotion_icon.png')}
              alt="Loading"
            />
            <BottomMenuLabel>{translate('promotions')}</BottomMenuLabel>
          </MenuBox>
        </MenuGrid>

        <MenuGrid
          item
          xs={12 / 5}
          onClick={() => {
            setMenuIndex(4);
            if (!isAuthenticated) {
              navigate('/auth/login-unprotected');
            } else {
              navigate('/user/wallet');
            }
          }}
        >
          <MenuBox>
            <Image
              src={require('../../../assets/menu/deposit_icon.png')}
              sx={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>{translate('deposit')}</BottomMenuLabel>
          </MenuBox>
        </MenuGrid>

        <MenuGrid
          item
          xs={12 / 5}
          onClick={() => {
            if (!isAuthenticated) {
              navigate('/auth/login-unprotected');
            } else {
              setMenuIndex(5);
              handleOpen();
            }
          }}
        >
          <MenuBox>
            <Image
              src={require('../../../assets/menu/settings_icon.png')}
              sx={{
                width: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
            />
            <BottomMenuLabel>{translate('settings')}</BottomMenuLabel>
          </MenuBox>
        </MenuGrid>
      </Grid>
    </BottomMenuBox>
  );
};

export default BottomMenu;