// @mui
import { enUS, frFR, zhCN, viVN, arSD } from '@mui/material/locale';
// routes
import { PATH_HOME } from './routes/paths';

// API
// ----------------------------------------------------------------------

export const HOST_API = process.env.REACT_APP_HOST_API_KEY || '';

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_HOME.root; // as '/home/app'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 88,
  DASHBOARD_DESKTOP_HEIGHT: 64,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 260,
  DASHBOARD_WIDTH: 210,
  DASHBOARD_COLLAPSE_WIDTH: 88,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const defaultSettings = {
  themeMode: 'light',
  themeDirection: 'ltr',
  themeContrast: 'default',
  themeLayout: 'horizontal',
  themeColorPresets: 'default',
  themeStretch: false,
};

// MULTI LANGUAGES
// Please remove `localStorage` when you change settings.
// ----------------------------------------------------------------------

export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Bahasa Melayu',
    value: 'ms',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_ms.svg',
  },
  {
    label: 'Bangla',
    value: 'bn',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_bn.svg',
  },
  {
    label: '中文',
    value: 'cn',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
  },
  {
    label: 'Vietnamese',
    value: 'vn',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
  // {
  //   label: 'Portugeuse',
  //   value: 'pt',
  //   systemValue: enUS,
  //   icon: '/assets/icons/flags/ic_flag_pt.svg',
  // },
  // {
  //   label: 'Burmese',
  //   value: 'my',
  //   systemValue: enUS,
  //   icon: '/assets/icons/flags/ic_flag_my.svg',
  // },
  // {
  //   label: 'Thai',
  //   value: 'th',
  //   systemValue: enUS,
  //   icon: '/assets/icons/flags/ic_flag_th.svg',
  // },
];

export const DEFAULT_COUNTRY_CODE = [
  {
    code: 'MY',
    label: 'Malaysia',
    phone: '60',
  },
  {
    code: 'PT',
    label: 'Brazil',
    phone: '55',
  },
  {
    code: 'TH',
    label: 'Thailand',
    phone: '66',
  },
  {
    code: 'MM',
    label: 'Myanmar',
    phone: '95',
  },
  {
    code: 'VN',
    label: 'Vietnam',
    phone: '84',
  },
  {
    code: 'BD',
    label: 'Bangladesh',
    phone: '880',
  },
  {
    code: 'AU',
    label: 'Australia',
    phone: '61',
  },
  {
    code: 'PH',
    label: 'PH',
    phone: '63',
  },
].find((f) => f.code === process.env.REACT_APP_COUNTRY_CODE);

export const DEFAULT_COUNTRY_SELECTIONS = [];

export const defaultLang = allLangs[1]; // English

export const telegramId = process.env.REACT_APP_TELEGRAM_ID || '';

export const SHARE_CONFIGS = {
  FB_APPID: process.env.REACT_APP_FB_APPID || '',
};
