import { useState, useEffect } from 'react';

export const usePageVisibility = () => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const handleVisibilityChange = () => {
      // console.log(`User ${document.hidden ? 'changed tab' : 'is in current tab '}`);
      setIsVisible(!document.hidden);
    };

    const handleFocus = () => {
      // console.log('User is active');
      setIsVisible(true);
    };

    const handleBlur = () => {
      // console.log('User is NOT active');
      setIsVisible(false);
    };

    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('focus', handleFocus);
    window.addEventListener('blur', handleBlur);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('focus', handleFocus);
      window.removeEventListener('blur', handleBlur);
    };
  }, []);

  return isVisible;
};
