import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Stack, styled, Typography } from '@mui/material';

import Iconify from '../../../components/Iconify';
import { useSelector } from '../../../redux/store';
import LanguagePopover from './LanguagePopover';
import Image from '../../../components/Image';

import palette from '../../../theme/palette';
import NotificationPopover from './NotificationPopover';

const ButtonContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: 'rgba(255, 255, 255, 0.8)',
  borderRadius: '8px',
  padding: '1px',
  boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
}));

const DashboardHeader = () => {
  const { siteSettings } = useSelector((x) => x.lookup);
  const navigate = useNavigate();

  const isAuthenticated = localStorage.getItem('accessToken');

  return (
    <Box sx={{ zIndex: -1 }}>
      <Stack direction="row" sx={{ mx: 2 }} alignItems="center">
        <Box sx={{ flexGrow: 1 }}>
          <Image
            src={require('../../../assets/brand-logo.png')}
            alt="Loading"
            sx={{
              width: { md: '14rem', sm: '26vw', xs: '26vw' },
              height: { md: '8rem', sm: '15vw', xs: '15vw' },
              mx: 'auto',
            }}
            aria-hidden="true"
            // ratio={'360 * 216'}
            onClick={() => navigate('/')}
          />
        </Box>

        <Stack
          justifyContent={'end'}
          direction={'row'}
          alignItems={'center'}
          spacing={1}
          sx={{ position: 'absolute', right: { md: '16px', sm: '3vw', xs: '3vw' }, top: '16px', sm: '3vw', xs: '3vw' }}
        >
          <ButtonContainer direction={'row'} alignItems={'center'}>
            <LanguagePopover />
          </ButtonContainer>
          {isAuthenticated && (
            <ButtonContainer
              direction={'row'}
              alignItems={'center'}
              justifyContent={'center'}
              sx={{ borderRadius: '50%', width: 35, height: 35 }}
            >
              <NotificationPopover />
            </ButtonContainer>
          )}
        </Stack>
      </Stack>

      <Box sx={{ width: '100%', opacity: 0.9 }}>
        {siteSettings?.announcement_text?.WalkPromotion?.content && (
          <Stack
            direction="row"
            alignItems={'center'}
            sx={{
              p: '0px 8px',
              background: palette.home.announcement.bg,
              cursor: siteSettings?.announcement_text?.WalkPromotion?.url ? 'pointer' : 'none',
            }}
            onClick={() => {
              if (siteSettings?.announcement_text?.WalkPromotion?.url) {
                window.open(siteSettings?.announcement_text?.WalkPromotion?.url, '_blank');
              }
            }}
          >
            <Iconify
              icon={'pepicons-pencil:megaphone'}
              color={palette.home.announcement.color}
              width={25}
              height={25}
              sx={{ mr: 1, minWidth: '25px', minHeight: '25px' }}
            />
            <Box className="marquee" sx={{ width: '100%', opacity: 1 }}>
              <Typography sx={{ color: 'white', fontWeight: 500 }}>
                {siteSettings?.announcement_text?.WalkPromotion?.content}
              </Typography>
            </Box>
          </Stack>
        )}
      </Box>
    </Box>
  );
};

export default DashboardHeader;
