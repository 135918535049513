import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const initialState = {
  isOpenForgotPwd: false,
  isOpenChangePwd: false,
  isPopupOpened: 0,
};

const slice = createSlice({
  name: 'authPages',
  initialState,
  reducers: {
    closeForgotPwd(state) {
      state.isOpenForgotPwd = false;
    },
    openForgotPwd(state) {
      state.isOpenForgotPwd = true;
    },
    closeChangePwd(state) {
      state.isOpenChangePwd = false;
    },
    openChangePwd(state) {
      state.isOpenChangePwd = true;
    },
    openPromoPopup(state) {
      state.isPopupOpened += 1;
    },
  },
});

// Reducer
export default slice.reducer;

// Actions
export const { openForgotPwd, openChangePwd, closeForgotPwd, closeChangePwd, openLoginSignup, openPromoPopup } =
  slice.actions;
