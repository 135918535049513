// ----------------------------------------------------------------------

// IF THIS TRANSLATION IS INCORRECT PLEASE IGNORE THIS AS THIS TRANSLATION IS FOR DEMO PURPOSES ONLY
// We are happy if you can help improve the translation by sending an email to support@minimals.cc.

// ----------------------------------------------------------------------

const bn = {
  game: `গেম`,
  games: `গেমস`,
  sport: `খেলা`,
  center: `মার্কেট`,
  mines: `মাইনস`,
  crash: `ক্রাশ`,
  lucky_colour: `লাকি কালার`,
  dice: `ডাইস`,
  limbo: `লিম্বো`,
  lucky_gift: `লাকি গিফট`,
  all_games: `সব গেমস`,
  game_history: `গেমের ইতিহাস`,
  coupon_code: `কুপন কোড`,
  telegram: `টেলিগ্রাম`,
  customer_service: `গ্রাহক সেবা`,
  player: `প্লেয়ার`,
  winner: `জয়ী`,
  all_bets: `সব বাজি`,
  my_bets: `আমার বাজি`,
  name: `নাম`,
  time: `সময়`,
  bet_amount: `বাজির পরিমাণ`,
  prediction: `ভবিষ্যদ্বাণী`,
  payout: `পেমেন্ট`,
  login: `লগইন`,
  register: `রেজিস্টার`,
  username_placeholder: `ইউজারনেম`,
  please_input_your_x: `দয়া করে আপনার {{x}} দিন`,
  captcha: `ক্যাপচা`,
  forgot_password: `পাসওয়ার্ড ভুলে গেছেন`,
  minor_note: `এই সাইটে প্রবেশ করতে, নিশ্চিত করুন যে আপনি ১৮ বছরের উপরে এবং সম্মত হন`,
  terms_of_service: `সেবার শর্তাবলী`,
  by_signing_in: 'লগইন বা সাইন আপ করে, আপনি আমাদের সাথে সম্মত হন',
  dont_have_account: `এখনো একটি অ্যাকাউন্ট নেই?`,
  sign_up: `সাইন আপ`,
  username: `ইউজারনেম`,
  email: `ইমেইল`,
  full_name: `পুরো নাম`,
  confirm_18: `আমি নিশ্চিত যে আমি ১৮ বছর বয়সী এবং আমি পড়েছি`,
  already_have_account: `এরই মধ্যে একটি অ্যাকাউন্ট আছে?`,
  share: `শেয়ার`,
  deposit: `ডিপোজিট`,
  bonus: `বোনাস`,
  app: `অ্যাপ`,
  load_more: `আরো লোড করুন`,
  details: `বিস্তারিত`,
  helps: `সাহায্য`,
  kyc_policy: `কেওয়াইসি নীতি`,
  privacy_policy: `গোপনীয়তা নীতি`,
  responsible_gaming: `দায়িত্বশীল গেমিং`,
  terms_and_condition: `শর্তাবলী`,
  faq: `প্রশ্নোত্তর`,
  about_us: `আমাদের সম্পর্কে`,
  amount: `পরিমাণ`,
  extra: `অতিরিক্ত`,
  deposit_bonus: `ডিপোজিট বোনাস`,
  do_not_participate: `প্রমোশনে অংশগ্রহণ করবেন না`,
  payment_problem: `পেমেন্ট সমস্যা`,
  click_for_help: `সাহায্যের জন্য ক্লিক করুন`,
  deposit_notes: `ডিপোজিট নোট`,
  available_balance: `উপলব্ধ ব্যালান্স`,
  withdraw: `উত্তোলন`,
  withdrawal_notes: `উত্তোলন নোট`,
  withdrawal_note1: `বর্তমান উত্তোলনের সংখ্যা`,
  withdrawal_note1_1: `ভিআইপি`,
  withdrawal_note2: `উত্তোলন ফি:`,
  min: `ন্যূনতম:`,
  max: `সর্বাধিক:`,
  opening_times: `খোলার সময়:`,
  withdrawal_note3: `১. দ্রুততম উত্তোলন সময় ১০ মিনিটের মধ্যে`,
  withdrawal_note4: `২. নিশ্চিত করুন যে আপনার উত্তোলনের তথ্য সঠিক, যদি উত্তোলনের তথ্য ভুল হয়, তবে এটি আপনার তহবিল হারাতে পারে।`,
  withdrawal_note5: `৩. আপনার কোনো প্রশ্ন থাকলে আমাদের সাথে যোগাযোগ করুন।`,
  notifications: `বিজ্ঞপ্তি`,
  invalid_x: `অবৈধ {{x}}`,
  captcha_not_match: `ক্যাপচা মেলেনি`,
  invalid_x_must_be_atleast_y_characters: `অবৈধ {{x}}, অন্তত {{y}} অক্ষর হতে হবে`,
  x_is_required: `{{x}} আবশ্যক`,
  phone_number: 'ফোন নম্বর',
  phone_num_x_max: `ফোন নম্বর সর্বাধিক {{x}} ডিজিট`,
  email_sent: `ইমেইল পাঠানো হয়েছে, অনুগ্রহ করে আপনার ইমেইল চেক করুন।`,
  enter_valid_email: `একটি বৈধ ইমেইল দিন।`,
  confirm: `নিশ্চিত করুন`,
  password_atleast_6_characters: `পাসওয়ার্ড অন্তত ৬ অক্ষর হতে হবে।`,
  password_reset_success: `পাসওয়ার্ড রিসেট সফল!`,
  new_password: `নতুন পাসওয়ার্ড`,
  bank: 'ব্যাংক',
  bank_transfer: `ব্যাংক ট্রান্সফার`,
  bank_name: `ব্যাংকের নাম`,
  account_name: `অ্যাকাউন্টের নাম`,
  account_number: `অ্যাকাউন্ট নম্বর`,
  pix_number: `পিক্স নম্বর`,
  drop_or_select: `ফাইল ড্রপ করুন বা নির্বাচন করুন`,
  drop_files_here: `ফাইল এখানে ড্রপ করুন`,
  transfer_to: `ট্রান্সফার করুন`,
  upload_receipt: `রসিদ আপলোড করুন`,
  select_bonus: `বোনাস নির্বাচন করুন`,
  no_promotion: `কোনও প্রচারনা নেই`,
  please_upload_receipt: `অনুগ্রহ করে রসিদ আপলোড করুন`,
  bank_account: `ব্যাংক অ্যাকাউন্ট`,
  bank_branch: `ব্যাংক শাখা`,
  pay_id: `পে আইডি`,
  add_account: `অ্যাকাউন্ট যোগ করুন`,
  account_holder_name: `অ্যাকাউন্ট ধারকের নাম`,
  name_of_the_account_holder: `অ্যাকাউন্ট ধারকের নাম`,
  fill_in_bank_account: `ব্যাংক অ্যাকাউন্ট পূরণ করুন`,
  fill_in_pay_id: `পে আইডি পূরণ করুন`,
  fill_in_bank_branch: `ব্যাংক শাখা পূরণ করুন`,
  withdraw_information: `উত্তোলন তথ্য`,
  note: `টিপ্পনী`,
  amount_withdrawn: `উত্তোলিত পরিমাণ`,
  amount_no_exceed_balance: `উত্তোলনের পরিমাণ উপলব্ধ ব্যালান্স ছাড়ানো উচিত নয়`,
  minimum_withdraw_amount_is_x: `ন্যূনতম উত্তোলনের পরিমাণ {{x}}`,
  transaction_history: `লেনদেনের ইতিহাস`,
  date_from: `তারিখ থেকে`,
  date_to: `তারিখ পর্যন্ত`,
  account_name_must_be_4: `অ্যাকাউন্টের নামের দৈর্ঘ্য ৪ অক্ষরের বেশি হতে হবে`,
  account_number_must_be_x: `অ্যাকাউন্ট নম্বর ১০-১৪ অক্ষরের মধ্যে হতে হবে`,
  minimum_length_is_x: `ন্যূনতম দৈর্ঘ্য {{x}}`,
  maximum_length_is_x: `সর্বাধিক দৈর্ঘ্য {{x}}`,
  payment_method: `পেমেন্ট পদ্ধতি`,
  mode: `মোড`,
  no_bank_account: `আপনার উত্তোলনের জন্য কোনো অ্যাকাউন্ট নেই, এখন যোগ করুন`,
  please_select_bank: `দয়া করে একটি ব্যাংক অ্যাকাউন্ট নির্বাচন করুন`,

  bet_history: `বাজির ইতিহাস`,
  game_provider: `গেম প্রদানকারী`,
  bet_count: `বাজির সংখ্যা`,
  win_loss: `জয়/হার`,
  rebate: `রিবেট`,
  turnover: `টার্নওভার`,
  game_category: `গেম ক্যাটাগরি`,

  you_have_x_unread_messages: `আপনার {{x}}টি অপরিচিত বার্তা রয়েছে`,
  deposit_amount_between_x_and_y: `ডিপোজিটের পরিমাণ {{x}} এবং {{y}} এর মধ্যে হতে হবে`,
  minimum_amount_is_x_y: `ন্যূনতম পরিমাণ {{x}}{{y}}`,

  // all
  all: `সব`,
  title: `শিরোনাম`,
  date: `তারিখ`,
  type: `প্রকার`,
  status: `অবস্থা`,
  remark: `মন্তব্য`,
  category: `ক্যাটাগরি`,
  please_select_x: `অনুগ্রহ করে {{x}} নির্বাচন করুন`,
  save: `সংরক্ষণ করুন`,

  change_password: `পাসওয়ার্ড পরিবর্তন করুন`,
  current_password: `বর্তমান পাসওয়ার্ড`,
  confirm_password: `নতুন পাসওয়ার্ড নিশ্চিত করুন`,
  submit: `জমা দিন`,
  password_changed: `পাসওয়ার্ড পরিবর্তিত হয়েছে`,
  x_must_be_y_characters: `{{x}} অন্তত {{y}} অক্ষরের হতে হবে`,
  reset_password: `পাসওয়ার্ড রিসেট করুন`,
  casino: `ক্যাসিনো`,
  slots: `স্লটস`,
  live_casino: `লাইভ ক্যাসিনো`,
  fishing: `ফিশিং`,
  sports: `খেলা`,
  cards: `কার্ড`,
  clear: `পরিষ্কার করুন`,
  search: `অনুসন্ধান`,
  new: `নতুন`,
  logout: `লগআউট`,
  choose_date: `তারিখ নির্বাচন করুন`,
  select_both_date: `তারিখ থেকে এবং পর্যন্ত উভয় নির্বাচন করুন`,
  from: `থেকে`,
  to: `পর্যন্ত`,
  apply: `প্রয়োগ করুন`,
  promotions: `প্রচার`,
  learn_more: `আরো জানুন`,
  password_doesnt_match: `পাসওয়ার্ড মেলে না`,
  cancel: `বাতিল করুন`,
  confirm_to_pay: `পেমেন্ট নিশ্চিত করুন`,
  deposit_amount: `ডিপোজিট পরিমাণ`,
  loading_please_wait: `লোড হচ্ছে, দয়া করে অপেক্ষা করুন`,
  account: `অ্যাকাউন্ট`,
  profile: `প্রোফাইল`,
  bank_list: `ব্যাংক তালিকা`,
  in_maintenance: `রক্ষণাবেক্ষণে`,
  birthday: `জন্মদিন`,
  birthday_bonus: `আপনার জন্মদিনের জন্য বোনাস নিন! 🎉🎁`,
  referral_code: `রেফারাল কোড`,
  friend_referral_code: 'বন্ধুর রেফারেল কোড',
  no_friend: '(বন্ধু না থাকলে ফাঁকা রাখুন)',
  referral: `রেফারাল`,
  my_referral: `আমার রেফারাল`,
  my_claim: `আমার দাবি`,
  claim_profit: `প্রফিট দাবি করুন`,
  available_profit: `উপলব্ধ প্রফিট`,
  claimed_profit: `দাবি করা প্রফিট`,
  all_time_profit: `সর্বকালের প্রফিট`,
  referral_note_on_x: `আপনার বন্ধুরা {{x}} তে খেললে প্রতি বার আপনাকে প্রফিট হবে`,
  tier: `টিয়ার`,
  commission: `কমিশন`,
  referred_member: `রেফার্ড সদস্য`,
  profit_earned: `উপার্জিত প্রফিট`,
  account_information: `অ্যাকাউন্টের তথ্য`,
  invite_friends: `বন্ধুদের আমন্ত্রণ করুন`,
  how_it_work: `এটি কীভাবে কাজ করে?`,
  invite_note: `আপনার রেফারাল কোড ব্যবহার করে আপনার বন্ধু সাইন আপ করলে এক্সক্লুসিভ রেফারাল বোনাস পান!`,
  share_via: `শেয়ার করুন`,
  referral_link: `রেফারাল লিঙ্ক`,
  total_profit: `মোট প্রফিট`,
  copied: `কপি করা হয়েছে`,
  nothing_to_claim: `দাবি করার কিছু নেই`,
  claim_history: `দাবির ইতিহাস`,
  downline: `আমার ডাউনলাইন`,
  last_x_digit_bank_ref_number: `ব্যাংক রেফ নম্বরের শেষ {{x}} ডিজিট`,
  bank_ref_number_validation_x: `ব্যাংক রেফ নম্বর প্রয়োজন এবং {{x}} অক্ষরের দৈর্ঘ্য হতে হবে`,
  sort: 'ছক',
  login_directly: `ডাইরেক্ট লগইন করুন`,
  our_game_provides: `আমাদের গেম প্রদানকারীরা`,
  refer_and_reward: `রেফার এবং পুরস্কৃত করুন`,
  linked_successfully_x: `সফলভাবে {{x}} এর সাথে লিঙ্ক করা হয়েছে! প্রথমবারের মতো আপনার অ্যাকাউন্ট নিবন্ধন করুন`,
  more_x_of_y: `আরো {{x}}/{{y}}`,
  default: `ডিফল্ট`,
  promotion: `প্রচার`,
  daily_mission: `দৈনিক মিশন`,
  agent: `এজেন্ট`,
  contact_us: `আমাদের সাথে যোগাযোগ করুন`,
  download: `ডাউনলোড`,
  cash_claim: `নগদ দাবি`,
  demo: `ডেমো`,
  favorites: `প্রিয়`,
  popular: `জনপ্রিয়`,
  claim_bonus: `বোনাস দাবি করুন`,
  insufficient_balance: `অপর্যাপ্ত ব্যালান্স`,
  currently_displaying: 'বর্তমানে {{x}} {{y}} গেম {{z}} এর মধ্যে প্রদর্শিত হচ্ছে',
  all_x: 'সব {{x}}',
  no_results: 'কোনো ফলাফল পাওয়া যায়নি',
  search_games: 'গেম অনুসন্ধান করুন',
  please_scan_using_your: `অনুগ্রহ করে আপনার মাধ্যমে স্ক্যান করুন`,
  vip_level: `ভিআইপি স্তর`,
  bonus_direct: `ডাইরেক্ট বোনাস`,
  rebate_level: `রিবেট স্তর`,
  vip_header: `ডবলউইনে স্লট মেশিন, বোর্ড এবং কার্ড গেমস, ফিশিং, ভিডিও, স্পোর্টস এবং লটারি গেমে রাখা সব বাজি স্থায়ীভাবে জমা হবে। বৈধ বাজি = স্থায়ী আয় এবং অর্থের সৌভাগ্য!`,
  vip_example: `
  উদাহরণ ১: সদস্যরা যারা ১ মিলিয়ন জমা করেন, তারা VIP স্তর ২ তে পৌঁছান, R$210 বোনাস পান এবং রিবেট ৩.৫০%, যখন তারা ৬ মিলিয়ন জমা করেন, তারা VIP স্তর ৩ তে পৌঁছান এবং R$800 বোনাস পান, রিবেট ৪% সহ`,
  activity_rules: `ক্রিয়াকলাপের নিয়ম:`,
  activity_rules_1: `১. সমস্ত বোনাসের জন্য উত্তোলন অনুরোধ করতে x3 রোলওভার প্রয়োজন`,
  activity_rules_2: `২. দয়া করে সময়সীমার মধ্যে রিবেট বোনাস দাবি করুন, দেরি হলে এটি ত্যাগ হিসেবে গণ্য হবে;`,
  activity_rules_3: `৩. সদস্যদের ক্রিয়াকলাপের অংশগ্রহণ স্বয়ংক্রিয়ভাবে সিস্টেম দ্বারা গণনা করা হয়, কোনো আপত্তি থাকলে, ডবলউইনের তদন্তের ফলাফল প্রযোজ্য হবে;`,
  activity_rules_4: `৪. যদি আপনি আপনার সদস্য অ্যাকাউন্ট/পাসওয়ার্ড ভুলে যান, দয়া করে "২৪-ঘণ্টা অনলাইন গ্রাহক সেবা" এর সাথে যোগাযোগ করুন যাতে তারা আপনার অ্যাকাউন্টের তথ্য পুনরুদ্ধার করতে সাহায্য করতে পারে;`,
  activity_rules_5: `৫. ডবলউইন যেকোনো সময় এই প্রচার পরিবর্তন, বন্ধ বা বাতিল করার অধিকার সংরক্ষণ করে।`,
  activity_rules_6: `৬. বিনামূল্যে গেম সংরক্ষণ করবেন না, বিনামূল্যে গেম কিনুন`,
  activity_rules_7: `৭. কোন বোনাসের সাথে মিলবে না`,
  activity_rules_8: `৮. প্রতিটি ডিপোজিটে সর্বাধিক উত্তোলন হল R$100.000.00`,
  event_instructions: 'ইভেন্ট নির্দেশনা',
  agent_p1: 'আপনার অনন্য লিঙ্কটি অ্যাপ এবং সোশ্যাল মিডিয়ায় শেয়ার করুন',
  agent_p2:
    'ফেসবুক, ইনস্টাগ্রাম, টিকটক, টেলিগ্রাম, হোয়াটসঅ্যাপ, ইউটিউব ইত্যাদির মাধ্যমে দ্রুত শেয়ার করুন এবং সবাইকে আমন্ত্রণ জানান, আপনার এক্সক্লুসিভ লিঙ্কের মাধ্যমে নিবন্ধিত সদস্যরা আপনার সরাসরি এজেন্ট হয়ে যাবে। তবে দয়া করে খেয়াল রাখুন যে আপনি যে নতুন সদস্যদের আমন্ত্রণ জানাচ্ছেন তারা প্রকৃত এবং বৈধ হতে হবে।',
  agent_step1:
    'আমন্ত্রণকৃত বন্ধুর প্রথম ডিপোজিট সফল হলে, আপনি R$5 বোনাস পেতে পারেন। উদাহরণস্বরূপ, যদি আপনি ১০ জনকে প্রথম ডিপোজিটের জন্য আমন্ত্রণ জানান, তবে আপনি R$50 উপার্জন করতে পারেন। যত বেশি লোক আমন্ত্রণ জানাবেন, তত বেশি বোনাস পাবেন। আপনার ইতিহাস দেখতে এবং প্রচারের পাতায় নিবন্ধন করতে পারেন।',
  agent_step2:
    'যদি আপনার অতিথিরা R$1 বা তার বেশি বাজি রাখে, আপনি ইতিমধ্যেই উচ্চ কমিশনের সুবিধা নিতে পারেন! আপনি সর্বাধিক ৫% জীবনকাল কমিশন এবং জীবনকাল আমন্ত্রণ উপভোগ করতে পারেন! সিস্টেম পরবর্তী দিনের সকাল ৭টায় পূর্ববর্তী দিনের কমিশন নিষ্পত্তি করবে।',
  agent_step2_1: 'কিভাবে পাবেন: লগইন - আমন্ত্রণ প্রচার - কমিশন সংগ্রহ করুন',
  agent_table1: 'স্লট, ফিশিং এবং ব্লকচেইনের জন্য বাজির কমিশন হার নিম্নরূপ:',
  agent_table1_1: 'গ্রাহক টপআপ ২০ বাজি ২০০',
  person: 'ব্যক্তি',
  agent_table2_title1: 'সদস্য বাজি',
  agent_table2_title2: 'স্লট গেম একমাত্র',
  agent_table2_example:
    'উদাহরণ: আপনার ১০০ সদস্য আছে, এবং প্রতিদিন বাজির মোট প্রকৃত মূল্য ১ মিলিয়ন রিয়েল বলে অনুমান করা হয়। ওইদিন আপনি যে কমিশন পাবেন তা হল: ১,০০০,০০০ x ১.২০% = ১২,০০০ রিয়েল। একটি মাসে ১,০০,০০০ আয় করা খুব সহজ।',
  reminder: 'স্মরণ',
  agent_reminder:
    'একই আইপি, একই ডিভাইস, নাম, ব্যাংক কার্ড এবং অন্যান্য তথ্য সহ একটি সদস্য অ্যাকাউন্ট শুধুমাত্র একবার অংশগ্রহণ করতে পারে, এবং যে কেউ নিয়ম ভঙ্গ করবে তাকে এই বোনাসের সুবিধা দেওয়া হবে না। অথবা গ্রুপটি বাজেয়াপ্ত করা হবে এবং অ্যাকাউন্টগুলি স্থায়ীভাবে জমা হবে',
  agent_step3_1:
    'সদস্যদের ক্রিয়াকলাপে অংশগ্রহণ স্বয়ংক্রিয়ভাবে সিস্টেম দ্বারা গণনা করা হয়, কোনো আপত্তি থাকলে, ডবলউইনের বিশ্লেষণের ফলাফল প্রযোজ্য হবে',
  agent_step3_2:
    'যদি আপনি আপনার সদস্য অ্যাকাউন্ট/পাসওয়ার্ড ভুলে যান, দয়া করে আমাদের ২৪-ঘণ্টার গ্রাহক সেবা দলের সাথে যোগাযোগ করুন যাতে তারা আপনার অ্যাকাউন্টের তথ্য পুনরুদ্ধার করতে সাহায্য করতে পারে',
  agent_step3_3: 'ডবলউইন যেকোনো সময় প্রচার পরিবর্তন, বিরতি বা বাতিল করার অধিকার সংরক্ষণ করে',
  agent_step3_4: 'এজেন্ট যে কমিশন বোনাস পাবে তা নগদ বা ওয়ালেটে ক্রেডিটের শর্তে হবে',
  agent_step3_5: 'বিনামূল্যে গেম সংরক্ষণ করবেন না, বিনামূল্যে গেম কিনুন',
  agent_step3_6: 'কোনো বোনাস এবং ডিপোজিটের সাথে মিলবে না',
  agent_step3_7: 'স্লট গেমের জন্য শুধুমাত্র',
  promo_concluded: 'প্রচার শেষ হয়েছে! আবার কাল চেক করুন!',
  can_only_claim: 'বোনাস শুধুমাত্র {{x}} থেকে {{y}} ব্রাজিল সময়ের মধ্যে দাবি করা যাবে',
  please_login: 'বোনাস দাবি করতে প্রথমে লগইন করুন!',
  back_to_home: 'হোমে ফিরে যান',
  bonus_claimed: 'বোনাস ইতিমধ্যেই দাবি করা হয়েছে!',

  // TODO: Translate To Other Lang!
  network_error: 'নেটওয়ার্ক ত্রুটি',
  we_have_sent_otp: 'আমরা আপনার ফোন নম্বরে একটি OTP পাঠিয়েছি',
  your_otp_expire: 'আপনার OTP শেষ হবে',
  didnt_receive: 'বার্তাটি পাননি?',
  send_again: 'আবার পাঠান',
  resend_again: 'আবার পাঠান',
  invalid_verification: 'অবৈধ যাচাইকরণ কোড!',
  otp_expired: 'OTP শেষ হয়ে গেছে, দয়া করে আপনার ফোন নম্বর পুনরায় প্রবেশ করুন এবং আবার চেষ্টা করুন!',
  otp_expired_redirect: 'OTP শেষ হয়ে গেছে! লগইন পৃষ্ঠায় রিডাইরেক্ট করা হচ্ছে',
  captcha_check_failed: 'ক্যাপচা চেক ব্যর্থ!',
  verify_phonenumber: 'ফোন নম্বর যাচাই করুন',
  send_otp: 'OTP পাঠান',
  success_verification: 'আপনার ফোন নম্বর যাচাই করা হয়েছে!',
  press_get_code: 'SMS এর মাধ্যমে যাচাইকরণ কোড পেতে কোড পান বাটনটি চাপুন',
  otp_sent: 'যাচাইকরণ কোড পাঠানো হয়েছে! চলতে যেতে নিচের কোডটি প্রবেশ করুন',
  get_code: 'কোড পান',
  verify: 'যাচাই করুন',
  verification_code: 'যাচাইকরণ কোড',
  otp_not_found: 'OTP ফাংশন পাওয়া যায়নি!',
  yes: `হ্যাঁ`,
  no: `না`,
  please_register_to_claim_bonus: `বোনাস দাবি করতে দয়া করে নিবন্ধন করুন!`,
  home: `হোম`,
  history: `ইতিহাস`,
  live_transactions: `লাইভ লেনদেন`,
  live: `লাইভ`,
  x_categories: `{{x}} ক্যাটেগরি`,
  x_category: `{{x}} বিভাগ`,
  live_chat: `লাইভ চ্যাট`,
  settings: 'সেটিংস',
  invite_friend: 'বন্ধুদের আমন্ত্রণ করুন',
  copy_referral_link: 'রেফারাল লিঙ্ক কপি করুন',
  more_info: 'আরও তথ্য',
  balance: 'ব্যালেন্স',
  level: 'স্তর',
  refresh: 'রিফ্রেশ',
  password: 'পাসওয়ার্ড',
  password_placeholder: '6 - 20 অক্ষর',
  optional: '(ঐচ্ছিক)',
  login_here: 'এখানে লগইন করুন',
  enter_text_on_the_left: 'বামে লেখা প্রবেশ করুন',
  number_exists: 'এই ফোন নম্বরটি ইতিমধ্যেই নিবন্ধিত হয়েছে!',
  number_not_exists: 'এই ফোন নম্বরটি নিবন্ধিত নয়!',
  back: 'ফিরে যা',
  please_download_x_to_play: '{{x}} খেলতে ডাউনলোড করুন',
  play_now: 'এখনই খেলুন',
};

export default bn;
