import React, { useState, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, Button, CardContent, InputAdornment, TextField } from '@mui/material';
import { countries } from '../_mock';
import Iconify from './Iconify';
import CustomModal from './CustomModal';
import { DEFAULT_COUNTRY_CODE, DEFAULT_COUNTRY_SELECTIONS } from '../config';
import { checkAndFormatPhoneNumber } from '../utils/formatNumber';
import useLocales from '../hooks/useLocales';

const getCountryCodeObj = (code) => {
  if (!code) return null;
  let countryCode = null;
  if (code) {
    const foundCode = countries.find((x) => x?.code === code);
    if (foundCode) countryCode = foundCode;
  }

  return countryCode;
};

const PhonenumberTextField = ({ onChange, value, selectCode = () => {}, selectAreaCode = () => {}, placeholder, ...others }) => {
  const [callingCode, setCallingCode] = useState(null);
  const [textFieldValue, setTextFieldValue] = useState(value || '');

  const { translate } = useLocales();

  const [openCodeSelect, setOpenCodeSelect] = useState(false);

  // const [openMenu, setOpenMenuActions] = useState(null);

  // const handleOpenMenu = (event) => {
  //   setOpenMenuActions(event.currentTarget);
  // };

  // const handleCloseMenu = () => {
  //   setOpenMenuActions(null);
  // };

  const optionalCode = useMemo(
    () => callingCode || getCountryCodeObj(checkAndFormatPhoneNumber(value || '').countryCode) || DEFAULT_COUNTRY_CODE,
    [callingCode, value]
  );

  const optionalValue = useMemo(
    () => textFieldValue || checkAndFormatPhoneNumber(value || '').formattedNumber || '',
    [textFieldValue, value]
  );

  const handleOpenMenu = () => {
    setOpenCodeSelect(true);
  };

  const handleCloseMenu = () => {
    setOpenCodeSelect(false);
  };

  const handleCallingCodeChange = (value) => {
    setCallingCode(value);
    selectCode(value?.code);
    selectAreaCode(value?.phone);
    handleCloseMenu();
    if (optionalValue) {
      onChange(`+${value.phone}${optionalValue}`);
    }
  };

  const handleTextFieldChange = useCallback(
    (event) => {
      const numericValue = event.target.value.replace(/\D/g, '');
  
      setTextFieldValue(numericValue);
  
      const finalValue = numericValue ? `+${optionalCode?.phone}${numericValue}` : '';
  
      onChange(finalValue);
    },
    [optionalCode, onChange]
  );
  

  return (
    <>
      <TextField
        fullWidth
        placeholder={placeholder}
        value={optionalValue}
        sx={{ position: 'relative', color: 'grey !important' }}
        InputProps={{
          startAdornment: (
            <InputAdornment placeholder={placeholder} position="start" sx={{ position: 'relative', color: 'grey' }}>
              {/* <Button
                variant="outlined"
                size="small"
                onClick={handleOpenMenu}
                endIcon={<Iconify icon={'mingcute:down-fill'} />}
                sx={{ mr: 0.5, border: '1px solid #1b1464', color: '#1b1464' }}
                tabIndex={-1}
              >
                {optionalCode?.code}
              </Button> */}
              +{optionalCode?.phone}
            </InputAdornment>
          ),
          sx: {
            borderRadius: '10px',
            height: '44px',
            p: '8.5px 13px',
            position: 'relative',
            backgroundColor: '#d3d3d3 !important',
            color: '#000 !important',
            '& input': {
              color: '#000 !important',
            },
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent !important',
            },
          },
        }}
        {...others}
        onChange={handleTextFieldChange}
      />
      <CustomModal
        fullWidth
        maxWidth="xs"
        title={translate('Select Country Code')}
        open={openCodeSelect}
        onClose={handleCloseMenu}
        sx={{ border: '1px solid #dd0000' }}
      >
        <CardContent>
          <Autocomplete
            // disablePortal
            fullWidth
            value={optionalCode}
            options={
              DEFAULT_COUNTRY_SELECTIONS && DEFAULT_COUNTRY_SELECTIONS?.length
                ? countries.filter((x) => DEFAULT_COUNTRY_SELECTIONS.includes(x?.code))
                : countries
            }
            getOptionLabel={(option) => `${option.label} +${option.phone}`}
            onChange={(e, v) => handleCallingCodeChange(v)}
            renderInput={(params) => <TextField fullWidth {...params} label="Country Code" />}
            sx={{ bgcolor: '#fff', color: '#000', borderColor: '#dd0000', borderRadius: 1 }}
          />
        </CardContent>
      </CustomModal>
    </>
  );
};

PhonenumberTextField.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  selectCode: PropTypes.func,
  selectAreaCode: PropTypes.func,
  placeholder: PropTypes.string,
};

export default PhonenumberTextField;
